import { ISolarPanel } from '@core/interfaces';
import { ICoordinate } from '@modules/Viewers/views/ModelViewer/interfaces/location';
import { VectorCoordinate, computeAngle } from './computeAngle';
import { getMinBoundingRectangleByArea } from './getMinBoundingRectangleByArea';
import { getSolarPanelsPointsFeatureCollection } from './getSolarPanelsPointsFeatureCollection';

export function getAzimuthOffset(solarPanels: ISolarPanel, coord: ICoordinate) {
  const pointsFeatureCollection = getSolarPanelsPointsFeatureCollection(solarPanels, coord);
  const minBoundingRectanglePolygon = getMinBoundingRectangleByArea(pointsFeatureCollection);
  const xy = minBoundingRectanglePolygon?.geometry.coordinates?.[0];

  let azimuthOffset = 0;

  if (xy) {
    const vertex1 = [xy[1][0] - xy[0][0], xy[1][1] - xy[0][1]] as VectorCoordinate;
    const vertex2 = [xy[2][0] - xy[1][0], xy[2][1] - xy[1][1]] as VectorCoordinate;
    const ref = [1, 0] as VectorCoordinate;

    let angleDegrees1 = computeAngle(vertex1, ref);
    let angleDegrees2 = computeAngle(vertex2, ref);

    if (angleDegrees1 !== null && angleDegrees1 > 90) {
      angleDegrees1 = 180 - angleDegrees1;
    }

    if (angleDegrees2 !== null && angleDegrees2 > 90) {
      angleDegrees2 = 180 - angleDegrees2;
    }

    if (angleDegrees2 !== null && (angleDegrees1 === null || angleDegrees1 > angleDegrees2)) {
      azimuthOffset = Math.sign(vertex2[0] * vertex2[1]) * angleDegrees2;
    } else if (
      angleDegrees1 !== null &&
      (angleDegrees2 === null || angleDegrees2 > angleDegrees1)
    ) {
      azimuthOffset = Math.sign(vertex1[0] * vertex1[1]) * angleDegrees1;
    }
  }

  return azimuthOffset;
}
