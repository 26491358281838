import Matrix from 'ml-matrix';

export function get2DArrayMatrixWithSelectedColumns(matrix: Matrix, start: number, end: number) {
  const selectedColumns: number[][] = [];
  const to2DArray = matrix.to2DArray();
  const numRows = matrix.rows;

  for (let row = 0; row < numRows; row += 1) {
    const slicedRow = to2DArray[row].slice(start, end);
    selectedColumns.push(slicedRow);
  }

  return new Matrix(selectedColumns);
}
