import { ChangeEvent, FC, HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';
import { useAutosize } from './hooks/useAutosize';
import styles from './styles.scss';

interface IProps extends HTMLAttributes<HTMLTextAreaElement> {
  initialValue?: string;
  onSend?: (value?: string) => void;
  onSave?: (value?: string) => void;
  onCancel?: (value?: boolean) => void;
}

export const Textarea: FC<IProps> = ({
  className,
  placeholder,
  initialValue,
  onSend,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue ?? '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useAutosize(textareaRef.current, value);

  const handleSendClick = () => {
    onSend && onSend(value);
    setValue('');
  };

  const handleCancelClick = () => {
    onCancel && onCancel(true);
    setValue('');
  };

  const handleSaveClick = () => {
    onSave && onSave(value);
    setValue('');
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.wrapper}>
        <TextareaAutosize
          minRows={1}
          ref={textareaRef}
          value={value}
          className={cn(styles.textarea, {
            [styles.withButton]: !!onSend,
            [styles.withTwoButtons]: !!onSave && !!onCancel,
          })}
          placeholder={placeholder}
          onChange={handleChange}
        ></TextareaAutosize>
        {onSend && (
          <button
            className={cn(styles.button, styles.sendButton, {
              [styles.primaryButton]: value,
            })}
            disabled={!value}
            onClick={handleSendClick}
          >
            {t('buttons.send')}
          </button>
        )}
        {onCancel && onSave && (
          <div className={styles.updateButtons}>
            <button className={cn(styles.button)} onClick={handleCancelClick}>
              {t('buttons.cancel')}
            </button>
            <button className={cn(styles.button, styles.primaryButton)} onClick={handleSaveClick}>
              {t('buttons.save')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
