import axios from 'axios';
import { createBearerToken } from '@core/utils';

interface IRequestModelParams {
  hostname: string;
  modelId: string;
  access_token: string;
  reservation_token: string;
  locId: string; // new parameter
  expname: string; // new parameter
}

export const loadModelById = async ({
  hostname,
  locId,
  modelId,
  expname,
  access_token,
  reservation_token,
}: IRequestModelParams) => {
  const requestData = {
    model_id: modelId,
    loc_id: locId,
    expname: expname,
  };

  return axios<boolean>(`https://${hostname}/model`, {
    method: 'POST',
    headers: {
      Authorization: createBearerToken(access_token),
      token: reservation_token,
    },
    data: requestData,
  }).then((res) => res.data);
};
