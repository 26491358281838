import * as turf from '@turf/turf';
import { ISolarPanel } from '@core/interfaces';
import { ICoordinate } from '@modules/Viewers/views/ModelViewer/interfaces/location';
import { geodetic2enu } from '@modules/Viewers/views/ModelViewer/utils/geo/geodetic2enu';

export function getSolarPanelsPointsFeatureCollection(
  solarPanels: ISolarPanel,
  coord: ICoordinate,
) {
  const { latitude: locLat, longitude: locLon, altitude: locAlt } = coord;

  const solarPanelsCoordinates = solarPanels.features.flatMap(
    (feature) => feature.geometry.coordinates[0],
  );

  const geodeticValues = solarPanelsCoordinates.map(([lon, lat, alt]) =>
    geodetic2enu(lat, lon, alt, locLat, locLon, locAlt),
  );
  const points = geodeticValues.map(([x, y]) => turf.point([x, y]));
  const pointsFeatureCollection = turf.featureCollection(points);

  return pointsFeatureCollection;
}
