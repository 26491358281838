export function aer2enu(azimuth: number, elevation: number, range: number) {
  // Convert azimuth and elevation to radians
  const az = (azimuth / 180) * Math.PI;
  const el = (elevation / 180) * Math.PI;

  // Calculate ENU coordinates
  const east = range * Math.sin(az);
  const north = range * Math.cos(az) * Math.cos(el);
  const up = range * Math.cos(az) * Math.sin(el);

  return [east, north, up];
}
