import { getColor } from '@core/utils';
import { ECreateSiteMapDrawLayers } from '../enums/layers';

export interface ICustomStyleArguments {
  outlineColor?: string;
  pointColor?: string;
  midpointColor?: string;
  fillColor?: string;
  idPrefix?: string;
}

const generateLayerId = (id: string, prefix?: string, sep = '.') =>
  [prefix, id].filter(Boolean).join(sep);

export const customStyles = ({
  outlineColor = getColor('--outflier-white'),
  pointColor = getColor('--outflier-white'),
  midpointColor = getColor('--outflier-white'),
  fillColor = getColor('--transparent'),
  idPrefix,
}: ICustomStyleArguments) => {
  return [
    // LINE STYLE for line_string mode
    {
      id: generateLayerId('gl-draw-line', idPrefix),
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },
    // Style for static line after drawing or editing is complete
    {
      id: generateLayerId('gl-draw-line-static', idPrefix),
      type: 'line',
      filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },

    // AREA STYLE for polygon mode
    {
      id: generateLayerId('gl-draw-polygon-fill', idPrefix),
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': fillColor,
        'fill-outline-color': outlineColor,
        'fill-opacity': 0.1, // Adjust to desired opacity for the fill of the polygon
      },
    },
    // LINE STYLE for polygon outlines
    {
      id: generateLayerId('gl-draw-polygon-stroke', idPrefix),
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },
    // POINT STYLES for polygon midpoints (between vertices)
    {
      id: generateLayerId('gl-draw-polygon-midpoint', idPrefix),
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'midpoint'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': midpointColor,
      },
    },

    // Style for static polygon after drawing or editing is complete
    {
      id: generateLayerId('gl-draw-polygon-static', idPrefix),
      type: 'line',
      filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },

    // POINT STYLES for line_string mode (endpoints) - ACTIVE
    {
      id: generateLayerId('gl-draw-point', idPrefix),
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 10,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for line_string mode (endpoints) - INACTIVE
    {
      id: generateLayerId('gl-draw-point-inactive', idPrefix),
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'false'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for polygon (vertices) - ACTIVE
    {
      id: generateLayerId('gl-draw-polygon-point', idPrefix),
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'vertex'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 10,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for non-active polygon (vertices)
    {
      id: generateLayerId('gl-draw-polygon-point-inactive', idPrefix),
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'vertex'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'false'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': pointColor,
      },
    },
  ];
};

export const MapStylePerimeter = customStyles({
  outlineColor: getColor('--outflier-blue'),
  fillColor: getColor('--outflier-white'),
  midpointColor: getColor('--outflier-blue'),
  idPrefix: ECreateSiteMapDrawLayers.Perimeter,
});

export const MapStyleNoFlyZone = customStyles({
  outlineColor: getColor('--red'),
  fillColor: getColor('--red'),
  idPrefix: ECreateSiteMapDrawLayers.NoFlyZones,
});

export const MapStyleObstacle = customStyles({
  outlineColor: getColor('--outflier-white'),
  idPrefix: ECreateSiteMapDrawLayers.Obstacles,
});
