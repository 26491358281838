import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import { DEFAULT_GENERATED_PROGRAM_NAME_WITH_INDEX_REGEX } from '@core/constants/createSite';
import { MAX_ZONE_NAME_LENGTH } from '@core/constants/validation';
import { EGeneratedProgramPreview } from '@core/enums/createSite';
import { EMode } from '@core/enums/mode';
import { useDispatchTyped } from '@core/hooks';
import { TEditZone } from '@core/interfaces';
import {
  setCurrentPlanIndex,
  setProgramPreview,
  updateProgramById,
  useCreateSiteZones,
} from '@core/store/slices';
import { sliceString } from '@core/utils';
import { Navbar } from '@modules/Sidebar/components';
import CommentSectionProgram from '@components/CommentSection/CommentSectionProgram';
import styles from './styles.scss';
import { IUpdateInputData } from '../../../Roles/interfaces/updateItem';

interface IProps {
  mode: EMode;
  zone: TEditZone;
  areDisabledSwitcherButtons?: boolean;
  siteName?: string;
  onBackClick?: () => void;
  onRightClick?: () => void;
  onLeftClick?: () => void;
  onChange: (data: IUpdateInputData) => void;
}

enum EOverviewZone {
  Option1 = 'option1',
  Option2 = 'option2',
  RequestCustomPlan = 'requestCustomPlan',
}

enum EInspectionZone {
  Option1 = 'option1',
  Option2 = 'option2',
  RequestCustomPlan = 'requestCustomPlan',
}

const SelectedZone: FC<IProps> = ({
  zone,
  siteName,
  mode,
  areDisabledSwitcherButtons,
  onBackClick,
  onRightClick,
  onLeftClick,
  onChange,
}) => {
  const { t } = useTranslation();
  const { currentPlanIndex, programPreview } = useCreateSiteZones();
  const [isEditedZoneName, setIsEditedName] = useState<boolean>(false);
  const [overview, setOverview] = useState<EOverviewZone | null>(
    zone?.customOverviewPlanRequested ? EOverviewZone.RequestCustomPlan : EOverviewZone.Option1,
  );
  const [inspection, setInspection] = useState<EInspectionZone | number | null>(
    zone?.customInspectionPlanRequested ? EInspectionZone.RequestCustomPlan : currentPlanIndex,
  );
  const dispatch = useDispatchTyped();

  useEffect(() => {
    setOverview(
      zone?.customOverviewPlanRequested ? EOverviewZone.RequestCustomPlan : EOverviewZone.Option1,
    );
    setInspection(zone?.customInspectionPlanRequested ? EInspectionZone.RequestCustomPlan : 0);
    dispatch(setCurrentPlanIndex(0));
  }, [zone.program_id]);

  const overviewZoneOptions = useMemo(
    () => [
      { name: 'Option 1', value: EOverviewZone.Option1 },
      { name: 'Request custom plan', value: EOverviewZone.RequestCustomPlan },
    ],
    [zone],
  );

  const inspectionsZoneOptions: { name: string; value: EInspectionZone | number }[] = [
    { name: 'Request custom plan', value: EInspectionZone.RequestCustomPlan },
  ];

  if (zone?.plans) {
    const additionalOptions: any[] = [];
    zone?.plans.forEach((program, index) => {
      additionalOptions.push({ name: `Option ${index + 1}`, value: index });
    });
    inspectionsZoneOptions.unshift(...additionalOptions);
  }

  const handleRadioGroupInspectionChange = (value: EInspectionZone | number) => {
    setInspection(value);
    dispatch(setProgramPreview(EGeneratedProgramPreview.Inspection));
    if (mode === EMode.Create && value !== EInspectionZone.RequestCustomPlan) {
      dispatch(
        updateProgramById({
          id: zone.program_id,
          changes: { custom_inspection_plan_requested: false },
        }),
      );
      if (typeof value == 'number') dispatch(setCurrentPlanIndex(value));
    } else {
      dispatch(
        updateProgramById({
          id: zone.program_id,
          changes: { custom_inspection_plan_requested: true },
        }),
      );
    }
  };

  const handleRadioGroupOverviewChange = (value: EOverviewZone) => {
    setOverview(value);
    dispatch(setProgramPreview(EGeneratedProgramPreview.Overview));
    if (mode === EMode.Create && value === EOverviewZone.Option1) {
      dispatch(
        updateProgramById({
          id: zone.program_id,
          changes: { custom_overview_plan_requested: false },
        }),
      );
    } else {
      dispatch(
        updateProgramById({
          id: zone.program_id,
          changes: { custom_overview_plan_requested: true },
        }),
      );
    }
  };

  const handleChangeProgramPreview = (programPreview: EGeneratedProgramPreview) => {
    dispatch(setProgramPreview(programPreview));
  };

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;

      setIsEditedName(true);
      if (name.length < MAX_ZONE_NAME_LENGTH) {
        onChange({
          [zone.program_id]: {
            value: e.target.value,
            errorMessage: null,
          },
        });
      }
    },
    [onChange],
  );

  return (
    <div className={cn({ 'p-5 pb-0': mode === EMode.Create }, styles.root)}>
      <Navbar
        header={`${sliceString(siteName ?? '', 12)} | ${zone.customName}`}
        headerClassName={'text-outflier-white text-2xl font-semibold leading-6 tracking-tight'}
        subheader={t('sidebar.site.edit.sections.zones.selectedZone.flightPlans') ?? ''}
        subheaderClassName={'text-outflier-white text-lg font-medium leading-5'}
        areDisabledSwitcherButtons={areDisabledSwitcherButtons}
        onBackClick={onBackClick}
        switcher={true}
        onRightClick={onRightClick}
        onLeftClick={onLeftClick}
      />

      <section className={cn({ 'pb-5': mode === EMode.Create }, styles.main)}>
        <div className={styles.zoneNameInput}>
          <label htmlFor={zone.program_id}>
            {t('sidebar.site.edit.sections.zones.selectedZone.zoneName')}
          </label>
          <input
            id={zone.program_id}
            name='name'
            value={
              !isEditedZoneName &&
              zone.name.value.match(DEFAULT_GENERATED_PROGRAM_NAME_WITH_INDEX_REGEX)
                ? ''
                : zone.name.value
            }
            onChange={handleNameChange}
            placeholder={'Add name'}
            autoComplete='off'
            className={styles.addInput}
          />
        </div>
        <div className={styles.radioGroupWrapper}>
          <RadioGroup
            className={styles.radioGroup}
            value={overview}
            onChange={handleRadioGroupOverviewChange}
            onClick={() => handleChangeProgramPreview(EGeneratedProgramPreview.Overview)}
          >
            <RadioGroup.Label className={styles.radioGroupLabel}>
              {t('sidebar.site.edit.sections.zones.selectedZone.overview')}
            </RadioGroup.Label>
            <div className={styles.options}>
              {overviewZoneOptions.map(({ name, value }) => (
                <RadioGroup.Option
                  key={name}
                  value={value}
                  className={styles.radioGroupOption}
                  disabled={mode === EMode.Edit}
                >
                  {({ checked }) => {
                    return (
                      <div className={styles.radioGroupOptionContent}>
                        <div className={styles.radioButtonWrapper}>
                          <div
                            className={cn(styles.radioButton, {
                              [styles.isCheckedOption]: checked,
                            })}
                          />
                        </div>
                        <RadioGroup.Description
                          as='div'
                          className={cn(
                            {
                              '!bg-outflier-blue':
                                programPreview === EGeneratedProgramPreview.Overview && checked,
                            },
                            styles.optionDescription,
                          )}
                        >
                          {name}
                        </RadioGroup.Description>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <RadioGroup
            className={styles.radioGroup}
            value={inspection}
            onChange={handleRadioGroupInspectionChange}
            onClick={() => handleChangeProgramPreview(EGeneratedProgramPreview.Inspection)}
          >
            <RadioGroup.Label className={styles.radioGroupLabel}>
              {t('sidebar.site.edit.sections.zones.selectedZone.inspection')}
            </RadioGroup.Label>
            <div className={styles.options}>
              {inspectionsZoneOptions.map(({ name, value }) => (
                <RadioGroup.Option
                  key={name}
                  value={value}
                  disabled={mode === EMode.Edit}
                  className={styles.radioGroupOption}
                >
                  {({ checked }) => {
                    return (
                      <div className={styles.radioGroupOptionContent}>
                        <div className={styles.radioButtonWrapper}>
                          <div
                            className={cn(styles.radioButton, {
                              [styles.isCheckedOption]: checked,
                            })}
                          />
                        </div>
                        <RadioGroup.Description
                          as='div'
                          className={cn(
                            {
                              '!bg-outflier-blue':
                                programPreview === EGeneratedProgramPreview.Inspection && checked,
                            },
                            styles.optionDescription,
                          )}
                        >
                          {name}
                        </RadioGroup.Description>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>

        <CommentSectionProgram programId={zone.program_id} />
      </section>
    </div>
  );
};

export default memo(SelectedZone);
