import { MutableRefObject, useEffect } from 'react';
import type { OrbitControls as TOrbitControls } from 'three-stdlib';
import { useModel3DSelector } from '@core/store/slices';

interface IResetOrbitControlsDependencies {
  viewerOrbitControlRef: MutableRefObject<TOrbitControls | null>;
  sidebarOrbitControlRef: MutableRefObject<TOrbitControls | null>;
}

export const useResetOrbitControls = ({
  viewerOrbitControlRef,
  sidebarOrbitControlRef,
}: IResetOrbitControlsDependencies) => {
  const { connections } = useModel3DSelector();

  useEffect(() => {
    if (!connections.socketConnection && !connections.webRTCConnection) {
      // Clean up orbit controls
      viewerOrbitControlRef.current?.dispose();
      sidebarOrbitControlRef.current?.dispose();

      viewerOrbitControlRef.current = null;
      sidebarOrbitControlRef.current = null;
    }
  }, [connections.socketConnection, connections.webRTCConnection]);
};
