import { t } from 'i18next';
import { EViewer } from '@core/enums';

export const getHeader = (mode: EViewer) => {
  const views: Record<EViewer, string> = {
    [EViewer.Map]: t('sidebar.inspectionView.header.map'),
    [EViewer.CompareMap]: t('sidebar.inspectionView.header.compareMap'),
    [EViewer.Image]: t('sidebar.inspectionView.header.pictures'),
    [EViewer.Model]: t('sidebar.inspectionView.header.model'),
    [EViewer.Video]: t('sidebar.inspectionView.header.video'),
  };

  return views[mode];
};
