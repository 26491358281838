import Matrix from 'ml-matrix';
import { ISolarPanel } from '@core/interfaces';
import { ICoordinate } from '@modules/Viewers/views/ModelViewer/interfaces/location';
import { aer2enu } from '@modules/Viewers/views/ModelViewer/utils/geo/aer2enu';
import { geodetic2enu } from '@modules/Viewers/views/ModelViewer/utils/geo/geodetic2enu';
import { get2DArrayMatrixWithSelectedColumns } from './get2DArrayMatrixColumns';
import { getAverageSolarPanelsCoordinates } from './getAverageSolarPanelsCoordinates';
import { getAzimuthOffset } from './getAzimuthOffset';
import {
  getTheBestPoseDatasetCameraPose,
  getTransformedDatasetCameraPoses,
} from './transform/getTransformedDatasetCameraPoses';

interface IInitCameraPositionOptions {
  locationCoordinate: ICoordinate;
  solarPanels: ISolarPanel;
  datasetCameraPoses: [number, number, number, number][] | null;
}

const TARGET_AZIMUTH = 225;
const DISTANCE_SCALING_FACTOR = 1.15;

export function getInitCameraPosition(options: IInitCameraPositionOptions) {
  const { datasetCameraPoses, solarPanels, locationCoordinate } = options;

  const azimuthOffset = getAzimuthOffset(solarPanels, {
    latitude: locationCoordinate.latitude,
    longitude: locationCoordinate.longitude,
    altitude: locationCoordinate.altitude,
  });

  const [xMean, yMean, zMean] = getAverageSolarPanelsCoordinates(solarPanels);
  const center = geodetic2enu(
    xMean,
    yMean,
    zMean,
    locationCoordinate.latitude,
    locationCoordinate.longitude,
    locationCoordinate.altitude,
  );

  if (datasetCameraPoses !== null) {
    const transformedDatasetCameraPoses = getTransformedDatasetCameraPoses(datasetCameraPoses);
    const datasetCameraPosesMatrix = new Matrix(transformedDatasetCameraPoses);
    const centerMatrix = new Matrix([center]);

    const datasetCameraPosesRecenteredENUMatrix =
      datasetCameraPosesMatrix.subRowVector(centerMatrix);
    const squaredDatasetCameraPosesRecenteredENUMatrix = Matrix.pow(
      datasetCameraPosesRecenteredENUMatrix,
      2,
    );

    const maxRadius = Matrix.sqrt(
      new Matrix([squaredDatasetCameraPosesRecenteredENUMatrix.getColumn(0)]).add(
        new Matrix([squaredDatasetCameraPosesRecenteredENUMatrix.getColumn(1)]),
      ),
    ).max();

    const targetPositionENU = aer2enu(TARGET_AZIMUTH - azimuthOffset, 0, maxRadius);

    const selectedDatasetRecenteredENUMatrix = get2DArrayMatrixWithSelectedColumns(
      datasetCameraPosesRecenteredENUMatrix,
      0,
      2,
    );

    const selectedTargetPositionENUMatrix = new Matrix([targetPositionENU.slice(0, 2)]);
    const diffSelectedDatasetRecenteredENUMatrix = selectedDatasetRecenteredENUMatrix.subRowVector(
      selectedTargetPositionENUMatrix,
    );
    const toArrayDiffSelectedDatasetRecenteredENUMatrix =
      diffSelectedDatasetRecenteredENUMatrix.to2DArray();

    const matrixFrobeniusNorm = toArrayDiffSelectedDatasetRecenteredENUMatrix.map((row) =>
      Matrix.rowVector(row).norm('frobenius'),
    );
    const bestPoseIdx = matrixFrobeniusNorm.indexOf(Math.min(...matrixFrobeniusNorm));

    const bestPoseDatasetCameraPoseMatrix = new Matrix([
      getTheBestPoseDatasetCameraPose(datasetCameraPoses, bestPoseIdx),
    ]);

    const bestPose = bestPoseDatasetCameraPoseMatrix.mul(DISTANCE_SCALING_FACTOR).to1DArray();

    const view = {
      up: {
        x: 0,
        y: 0,
        z: 1,
      },
      center: {
        x: center[0],
        y: center[1],
        z: center[2],
      },
      eye: {
        x: bestPose[0],
        y: bestPose[1],
        z: bestPose[2],
      },
    };

    return view;
  }
}
