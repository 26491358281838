import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { ESidebar } from '@core/enums';
import { INoFlyZone } from '@core/interfaces/createSite';
import { customizeTrash } from '@modules/Viewers/views/MapViewer/utils/customizeTrash';
import { MapStyleNoFlyZone } from '@modules/Viewers/views/MapViewer/utils/mapboxStyles';
import { ECreateSiteLayers, ECreateSiteMapDrawLayers } from '../../../enums/layers';
import { removeLayersAndSources } from '../../removeLayersAndSources';
import { updateLayerOrdering } from '../obstacles/updateLayerOrdering';

interface IProps {
  map: mapboxgl.Map | null;
  noFlyZone?: INoFlyZone | null;
  sidebar: ESidebar;
  onGeoJsonChange: (geoJson: FeatureCollection) => void;
}

let draw: MapboxDraw | null = null;

export const addEditNoFlyZone = (props: IProps) => {
  const { map, noFlyZone, sidebar, onGeoJsonChange } = props;
  const noFlyZonesPerimeter = noFlyZone?.perimeter;

  if (!map) return;

  if (sidebar !== ESidebar.CreateSite || !noFlyZonesPerimeter) {
    removeLayersAndSources(map, {
      ['geojson']: ['geojson'],
    });

    if (draw) {
      map.removeControl(draw);
      draw = null;
    }
    return;
  }

  const updateGeoJson = () => {
    if (!draw) return;
    let data = draw.getAll();

    if (data?.features.length > 1) {
      const featureId = data.features[0]?.id;

      if (featureId) {
        draw.delete(featureId.toString());
        data = draw.getAll();
      }
    }

    if (data?.features.length > 0) {
      onGeoJsonChange(data);
    }
  };

  if (!map.getSource('geojson')) {
    map.addSource('geojson', {
      type: 'geojson',
      data: noFlyZonesPerimeter,
    });
  } else {
    (map.getSource('geojson') as mapboxgl.GeoJSONSource).setData(turf.feature(noFlyZonesPerimeter));
  }

  if (!draw) {
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: true,
      },
      styles: MapStyleNoFlyZone,
    });

    map.addControl(draw, 'top-right');

    customizeTrash();

    map.on('draw.create', updateGeoJson);
    map.on('draw.delete', updateGeoJson);
    map.on('draw.update', updateGeoJson);
  }

  if (noFlyZonesPerimeter?.coordinates?.length === 0) {
    setTimeout(() => {
      draw?.changeMode('draw_polygon');
    }, 100);
  }

  if (draw) {
    draw.deleteAll();
    draw.add(noFlyZonesPerimeter);

    const addedFeatures = draw.getAll();

    if (addedFeatures?.features?.length) {
      const featureId = addedFeatures.features[0].id;
      if (featureId) {
        draw.changeMode('direct_select', { featureId: featureId.toString() });
      }
    }

    updateLayerOrdering(map, {
      patterns: {
        beforeId: new RegExp(`^${ECreateSiteLayers.Perimeter}`),
        afterIds: [new RegExp(`^${ECreateSiteMapDrawLayers.NoFlyZones}`)],
      },
    });
  }
};
