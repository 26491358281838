export enum ECreateSiteSections {
  SearchLocation = 'searchLocation',
  Name = 'name',
  Perimeter = 'perimeter',
  NoFlyZones = 'noFlyZones',
  Obstacles = 'obstacles',
  Picture = 'picture',
  InspectionFrequency = 'inspectionFrequency',
  Roles = 'roles',
  Zones = 'zones',
}

export enum EGeneratedProgramPreview {
  Overview = 'Overview',
  Inspection = 'Inspection',
}
