import { fromRadiansToDegrees } from '@core/utils/converting/fromRadiansToDegrees';

/**
 *  Compute angle of a vector given by two points in plane
 * @param {number} vector1 -  x and y of first vector
 * @param {number} vector2 - x and y of second vector
 *
 * @return: Angle in rads. Return nan if at least one of the vector has zero norm
 */

export type VectorCoordinate = [number, number];

export function computeAngle(vector1: VectorCoordinate, vector2: VectorCoordinate) {
  const [x1, y1] = vector1;
  const [x2, y2] = vector2;

  const dotProduct = x1 * x2 + y1 * y2;
  const modOfVectors = Math.sqrt(x1 ** 2 + y1 ** 2) * Math.sqrt(x2 ** 2 + y2 ** 2);

  // Check that none of the vectors is null
  if (modOfVectors !== 0) {
    const cosAngle = dotProduct / modOfVectors;
    return fromRadiansToDegrees(Math.acos(cosAngle));
  } else {
    console.warn('Warning: one of the vectors is null, returning None');
    return null;
  }
}
