/* eslint-disable no-constant-condition */
import { delay } from '@core/utils/delay';

export enum ELayerOrder {
  AfterAll = 'AFTER_ALL',
  AfterBeforeId = 'AFTER_BEFORE_ID',
}

interface Params {
  order?: ELayerOrder;
  patterns: {
    beforeId: RegExp;
    afterIds: RegExp[];
  };
  mapRequests?: {
    delayMs: number;
    attempts: number;
  };
}

const defaultMapRequestParams = {
  delayMs: 100,
  attempts: 5,
};

const validationErrors = {
  notFound: (patterns: RegExp[]) => `Layer with pattern ${patterns.join(', ')} not found`,
  maxAttempts: () => 'Max attempts "getStyles" calls to map',
};

export async function updateLayerOrdering(
  map: mapboxgl.Map,
  {
    order = ELayerOrder.AfterAll,
    patterns: { beforeId: beforeIdPattern, afterIds: afterIdsPatterns },
    mapRequests = defaultMapRequestParams,
  }: Params,
) {
  const { delayMs, attempts } = mapRequests;

  let mapboxDrawLayers = map.getStyle().layers;
  let beforeIds: string[] = [];
  let afterIds: string[] = [];

  try {
    outerLoop: while (true) {
      for (let attempt = 0; attempt < attempts; attempt += 1) {
        await delay(delayMs);

        mapboxDrawLayers = map.getStyle().layers;

        const layerIds = mapboxDrawLayers.map((layer) => layer.id);

        beforeIds = layerIds.filter((layerId) => beforeIdPattern.test(layerId));
        afterIds = layerIds.filter((id) =>
          afterIdsPatterns.some((afterIdPattern) => afterIdPattern.test(id)),
        );

        // NOTE: layers by patterns are found
        if (beforeIds.length && afterIds.length) {
          break outerLoop;
        }
      }

      const messages: string[] = [];

      if (!beforeIds.length) messages.push(validationErrors.notFound([beforeIdPattern]));
      if (!afterIds.length) messages.push(validationErrors.notFound(afterIdsPatterns));

      const errorMessage = messages.length ? messages.join('\n') : validationErrors.maxAttempts();

      throw new Error(errorMessage);
    }

    switch (order) {
      case ELayerOrder.AfterAll:
        afterIds.forEach((afterId) => {
          map.moveLayer(afterId);
        });

        break;
      case ELayerOrder.AfterBeforeId:
        beforeIds.forEach((beforeId) => {
          afterIds.forEach((afterId) => {
            map.moveLayer(afterId, beforeId);
          });
        });
        break;
    }
  } catch (err) {
    if (err instanceof Error) {
      console.error(err.message);
    }
  }
}
