import React, { memo, useRef } from 'react';
import _ from 'lodash';
import * as THREE from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Wireframe } from 'three/examples/jsm/lines/Wireframe';
import { WireframeGeometry2 } from 'three/examples/jsm/lines/WireframeGeometry2';
import { getColor } from '@core/utils';
import { DEFAULT_DRAWABLE, TYPE_DRAWABLE, drawableObjectRenderOrder } from '../constants/drawables';
import { createCircleTextureWithText } from '../utils/createCircleTexture';
import { createTriangleTexture } from '../utils/createTriangleTexture';
import { getFixedVertexShaderForFlightPath } from '../utils/getFixedVertexShaderForFlightPath';

// Use z-up coordinate system by default. .js files don't see global variables from .ts files
THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1);

function areEqualProps(prevProps, nextProps) {
  if (_.isEqual(prevProps.drawables, nextProps.drawables)) {
    return true;
  }
  return false;
}

// NOTE: There are problems with .ts. It can't handle "onClick" on group.
export const Drawables = memo(({ drawables }) => {
  if (drawables === null || !drawables.length) {
    return null;
  }

  const groupRef = useRef();
  const drawable_objects = new THREE.Group();
  const colmapToThreejsRot = new THREE.Matrix4().makeRotationX(Math.PI);

  // For each drawable
  for (let drawable of drawables) {
    drawable = { ...DEFAULT_DRAWABLE, ...drawable };

    let matrix = new THREE.Matrix4().fromArray(drawable.transform.flat()).transpose();
    if (drawable.coordinate_convention == 'colmap') {
      matrix = matrix.multiply(colmapToThreejsRot);
    }

    let drawable_object = null;
    if (drawable.id === 'flight_path') {
      const geometry = new THREE.BufferGeometry().setFromPoints(drawable.vertices);
      const meshLine = new MeshLine();
      meshLine.setGeometry(geometry);

      const meshLineMaterialOptions = {
        color: new THREE.Color(drawable.color),
        depthWrite: false,
        depthTest: false,
        transparent: true,
        opacity: drawable.opacity,
        sizeAttenuation: true,
        lineWidth: drawable.line_width,
      };

      const meshLineMaterial = new MeshLineMaterial(meshLineMaterialOptions);
      // NOTE: see the description of the problem in the function
      meshLineMaterial.vertexShader = getFixedVertexShaderForFlightPath();

      drawable_object = new THREE.Mesh(meshLine, meshLineMaterial);
      drawable_object.renderOrder = drawableObjectRenderOrder.get('flight_path');
      drawable_object.userData.isLine = true;
    } else {
      const geometry = new THREE.BufferGeometry();

      const vertices = new Float32Array(drawable.vertices);
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      // Draw a triangular mesh
      if (drawable.faces.length) {
        const indices = new Uint32Array(drawable.faces);
        geometry.setIndex(new THREE.BufferAttribute(indices, 1));

        // TODO: for line width, need to use ---> https://github.com/spite/THREE.MeshLine/blob/master/README.md
        if (drawable.wireframe) {
          const wireframe_geom = new WireframeGeometry2(geometry);

          const lineMaterialOptions = {
            color: new THREE.Color(drawable.color),
            opacity: drawable.alpha,
            transparent: true,
            linewidth: drawable.line_width,
            dashed: false,
            depthWrite: false,
          };

          const material = new LineMaterial(lineMaterialOptions);
          material.side = THREE.DoubleSide;

          const wireframe = new Wireframe(wireframe_geom, material);
          wireframe.computeLineDistances();
          wireframe.scale.set(1, 1, 1);
          drawable_object = wireframe;
          drawable_object.renderOrder = drawableObjectRenderOrder.get('wireframe');
        } else {
          const meshMaterialOptions = {
            color: drawable.color,
            metalness: 0.0,
            roughness: 0.5,
            opacity: drawable.alpha,
            transparent: true,
            depthWrite: false,
          };

          const material = new THREE.MeshStandardMaterial(meshMaterialOptions);

          material.wireframe = drawable.wireframe;
          material.side = THREE.DoubleSide;
          material.flatShading = true;
          drawable_object = new THREE.Mesh(geometry, material);
          drawable_object.renderOrder = drawableObjectRenderOrder.get('mesh');
        }
      } else {
        // Draw a point cloud
        const batteryChangeProps = [
          drawable.color,
          drawable.point_size,
          drawable.index,
          getColor('--outflier-white'),
        ];
        const sampleProps = [
          drawable.color,
          drawable.point_size,
          null,
          null,
          drawable.border_color,
          drawable.border_width,
        ];

        const [props, renderOrder] = drawable.index
          ? [batteryChangeProps, drawableObjectRenderOrder.get('battery')]
          : [sampleProps, drawableObjectRenderOrder.get('sample')];

        const material = new THREE.PointsMaterial({
          size: drawable.point_size,
          sizeAttenuation: false,
          map:
            drawable.id === 'flight_path_end'
              ? createTriangleTexture(getColor('--outflier-blue'), drawable['point_size'])
              : createCircleTextureWithText(...props),
          transparent: true,
          opacity: drawable.alpha,
          depthWrite: false,
        });

        drawable_object = new THREE.Points(geometry, material);
        drawable_object.renderOrder = renderOrder;
      }
    }

    drawable_object.geometry.computeBoundingSphere();
    drawable_object.matrixAutoUpdate = false;
    drawable_object.matrix = matrix;

    // Add metadata
    drawable_object.userData.type = TYPE_DRAWABLE;
    drawable_object.userData.id = drawable.id;
    drawable_object.userData.color = new THREE.Color(drawable.color);
    drawable_object.userData.alpha = drawable.alpha;
    drawable_object.userData.selected_color = new THREE.Color(drawable.selected_color);
    drawable_object.userData.selected_alpha = drawable.selected_alpha;

    drawable_objects.add(drawable_object);
  }

  if (groupRef.current?.children.length) {
    // Clean up drawables
    groupRef.current.children.forEach((child) => {
      child.geometry.dispose();
      // Clean up texture
      child.material.map?.dispose();
      child.material.dispose();
    });
    groupRef.current.children = [];
  }

  return (
    <group ref={groupRef} dispose={null}>
      {drawable_objects.children.map((object, i) => (
        <primitive object={object} key={`drawable_${i}`} />
      ))}
    </group>
  );
}, areEqualProps);
