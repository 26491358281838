export enum ESiteStatuses {
  Normal = 'normal',
  Issues = 'issues',
  Inspected = 'inspected',
  ReportProcessing = 'reportProcessing',
}

// NOTE: the count is amount of weeks for defined period
export enum EInspectionFrequency {
  Monthly = 4,
  HalfYearly = 26,
  Yearly = 52,
}
