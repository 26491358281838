import { AllGeoJSON, Feature, Polygon, Properties } from '@turf/helpers';
import * as turf from '@turf/turf';

export function getMinBoundingRectangleByArea(geojson: AllGeoJSON) {
  const convexHull = turf.convex(geojson);
  let resultPolygon: Feature<Polygon, Properties> | null = null;

  if (convexHull) {
    const centroidCoords = turf.centroid(convexHull);
    const allHullCoords = turf.coordAll(convexHull);

    let minArea = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < allHullCoords.length - 1; i += 1) {
      const angle = turf.bearing(allHullCoords[i], allHullCoords[i + 1]);

      const rotatedHull = turf.transformRotate(convexHull, -1.0 * angle, {
        pivot: centroidCoords,
      });

      const envelopeOfHull = turf.envelope(rotatedHull);
      const envelopeArea = turf.area(envelopeOfHull);
      if (envelopeArea < minArea) {
        minArea = envelopeArea;
        resultPolygon = turf.transformRotate(envelopeOfHull, angle, {
          pivot: centroidCoords,
        });
      }
    }
  }

  return resultPolygon;
}
