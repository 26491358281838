import mapboxgl from 'mapbox-gl';
import { getColor } from '@core/utils';

interface ICreateAnomalyPopup {
  map: mapboxgl.Map;
  anomalyFeature: mapboxgl.MapboxGeoJSONFeature;
  coordinates: mapboxgl.LngLat;
}

export const POPUP_ANOMALY_ID = 'popup-anomaly';
export const createAnomalyPopup = ({ map, anomalyFeature, coordinates }: ICreateAnomalyPopup) => {
  map.getCanvas().style.cursor = 'pointer';

  const popup = document.createElement('div');
  popup.id = POPUP_ANOMALY_ID;
  popup.dataset.id = anomalyFeature.id?.toString();
  popup.style.padding = '3px 6px';
  popup.style.fontSize = '12px';
  popup.style.lineHeight = '1';
  popup.style.fontWeight = '500';
  popup.style.fontFamily = 'Inter, system-ui';
  popup.style.borderRadius = '3px';
  popup.style.border = `1px solid ${getColor('--outflier-color5')}`;
  popup.style.display = 'flex';
  popup.style.flexDirection = 'column';
  popup.style.rowGap = '3px';
  popup.style.alignItems = 'center';
  popup.style.backgroundColor = anomalyFeature.properties?.color;
  popup.style.color = '#00000F';
  popup.innerHTML = `<span>Anomaly</span><span>#${anomalyFeature.id}</span>`;
  new mapboxgl.Marker(popup).setLngLat(coordinates).addTo(map);
};
