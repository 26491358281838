/* eslint-disable no-case-declarations */
// TODO:

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { ECreateSiteSteps, FAKE_PERCENTAGE_STEP } from '@core/constants/createSite';
import {
  ECookiesField,
  ECreateSiteLocalStorageField,
  ELocalStorageField,
} from '@core/constants/storage';
import { ESidebar } from '@core/enums';
import { EConnectionStatus } from '@core/enums/connection';
import { ECreateSiteSections } from '@core/enums/createSite';
import { ESiteEditSections } from '@core/enums/editSite';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { ELocationStatus } from '@core/interfaces';
import { IBrowserStorage } from '@core/interfaces/accessControl';
import { IFakePerimeterProcessing, IZonesPerimeterStorageData } from '@core/interfaces/createSite';
import { EPersistorStatus } from '@core/services/persistor/enums';
import { EMergeStrategy } from '@core/services/persistor/strategies';
import { DataProcessing } from '@core/services/processing';
import { browserLocalStorage, cookiesStorage } from '@core/services/storage';
import { IPersistPermission } from '@core/services/storage/interfaces/permissions';
import { persistor } from '@core/store';
import {
  loadZones,
  resetCreateSite,
  setBrowserStorageError,
  setCurrentStep,
  setFakePerimeterProcessing,
  setIsDisabledLogout,
  setIsLoadingSite,
  setPerimeterProcessingPercentage,
  setSidebar,
  useAccessControlSelector,
  useCreateSiteName,
  useCreateSiteNoFlyZones,
  useCreateSiteObstacles,
  useCreateSitePerimeter,
  useCreateSiteRoles,
  useCreateSiteSelectedLocation,
  useCreateSiteSteps,
  useCreateSiteZones,
  useNetworkConnectionSelector,
} from '@core/store/slices';
import { reloadMap } from '@core/store/slices/map';
import { areAllValuesValid, isNull, isOneValueValid, validateSiteName } from '@core/utils';
import { Navbar } from '@modules/Sidebar/components';
import InspectionFrequencyCreate from '@modules/Sidebar/components/AccordionView/BodyOptions/InspectionFrequency/InspectionFrequencyCreate';
import NameCreate from '@modules/Sidebar/components/AccordionView/BodyOptions/Name/NameCreate';
import NoFlyZonesPanel from '@modules/Sidebar/components/AccordionView/BodyOptions/NoFlyZones/NoFlyZonesBody';
import ObstaclesPanel from '@modules/Sidebar/components/AccordionView/BodyOptions/Obstacles/ObstaclesBody';
import PerimeterPanel from '@modules/Sidebar/components/AccordionView/BodyOptions/Perimeter/PerimeterBody';
import PictureCreate from '@modules/Sidebar/components/AccordionView/BodyOptions/Picture/PictureCreate';
import RolesCreate from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/RolesCreate';
import ZonesCreate from '@modules/Sidebar/components/AccordionView/BodyOptions/Zones/ZonesCreate';
import AccordionHeadOption from '@modules/Sidebar/components/AccordionView/HeadOption/AccordionHead';
import SearchLocationStep from '@modules/Sidebar/views/CreateSite/components/Steps/SearchLocationStep';
import { convertEditInfoRolesToIAddNewUsersRequest } from '@modules/Sidebar/views/CreateSite/utils/editInfoRolesToIAddNewUsersRequest/editInfoRolesToIAddNewUsersRequest';
import Accordion from '@components/Accordion/Accordion';
import { Button } from '@components/Button';
import { EButtonColor } from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './styles.scss';
import { getFakePercentage } from './utils/getFakePercentage';

interface IProps {}

type TCreateSiteStorageData = Record<string, { [key in ELocalStorageField]?: any }>;

const NUMBER_OF_STEPS = 9;

export const SiteCreation: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const locationId = useSelectorTyped((state) => state.createSite.loc_id);
  const { lat: createSiteLat } = useCreateSiteSelectedLocation();
  const { current: currentStep, maxCurrent: maxCurrentStep } = useCreateSiteSteps();
  const {
    isLoadingSite,
    processingPercentage: perimeterProcessingPercentage,
    gps_boundaries,
    data: perimeters,
  } = useCreateSitePerimeter();
  const { fakePerimeterProcessing, isLoadingZones, programs } = useCreateSiteZones();
  const { name } = useCreateSiteName();
  const { data: obstacles } = useCreateSiteObstacles();
  const { data: noFlyZones } = useCreateSiteNoFlyZones();
  const roles = useCreateSiteRoles();

  const {
    bottom_right_lat: bottomRightLat,
    bottom_right_lng: bottomRightLng,
    top_left_lat: topLeftLat,
    top_left_lng: topLeftLng,
  } = gps_boundaries;

  const networkConnection = useNetworkConnectionSelector();
  const { browserStorage } = useAccessControlSelector();

  const [isOpenDeleteSiteModal, setIsOpenDeleteSiteModal] = useState(false);
  const [isRequestFlightPlanApproval, setIsRequestFlightPlanApproval] = useState(false);

  const currentFakePerimeterPercentage = useRef<number>(fakePerimeterProcessing.percentage);
  currentFakePerimeterPercentage.current = fakePerimeterProcessing.percentage;

  const currentBrowserStorageRef = useRef<IBrowserStorage | null>(browserStorage);
  currentBrowserStorageRef.current = browserStorage;

  const handleNextStep = async () => {
    if (currentStep === ECreateSiteSteps.SearchCity) {
      const response = await handleCreatePerimeter();
      if (!response || response.hasError) return;
    }
    if (currentStep === ECreateSiteSteps.Name && isLoadingZones) {
      dispatch(setCurrentStep(ECreateSiteSteps.Picture));
      return;
    }
    if (currentStep === ECreateSiteSteps.Obstacles && programs.length === 0) {
      setTimeout(() => {
        dispatch(loadZones());
      }, 100);
    }
    if (currentStep === ECreateSiteSteps.Roles) {
      handleCreateUsers();
    }
    if (currentStep === ECreateSiteSteps.Zones) {
      handleOpenRequestFlightPlanApprovalSiteModal();
    }
    if (currentStep < NUMBER_OF_STEPS - 1) {
      dispatch(setCurrentStep(currentStep + 1));
      dispatch(reloadMap(true));
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) dispatch(setCurrentStep(currentStep - 1));
    dispatch(reloadMap(true));
  };

  const updateStoragePersistPermission = useCallback((permissionsData: IPersistPermission) => {
    const serializedValue = DataProcessing.serialize(permissionsData);
    cookiesStorage.setItem(ECookiesField.StoragePersistPermission, serializedValue);
  }, []);

  const enableStoragePersistPermission = useCallback(() => {
    const serializedValue = DataProcessing.serialize({ isPermissionGranted: true });
    cookiesStorage.setItem(ECookiesField.StoragePersistPermission, serializedValue);
  }, []);

  const disableStoragePersistPermission = useCallback(() => {
    cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
  }, []);

  const getZonesPerimeterCalculationDataFromStorage = useCallback(() => {
    const createSiteStorageState =
      DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ?? {};

    const perimeterCalculationItem = !isNull(locationId)
      ? (createSiteStorageState?.[locationId]?.[
          ECreateSiteLocalStorageField.CurrentZonesPerimeterCalculation
        ] as IZonesPerimeterStorageData)
      : null;

    return perimeterCalculationItem;
  }, [locationId]);

  const setZonesPerimeterCalculationDataToStorage = useCallback(() => {
    if (locationId) {
      const createSiteStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ??
        {};

      const payloadData = {
        [ECreateSiteLocalStorageField.CurrentZonesPerimeterCalculation]: {
          percentage: fakePerimeterProcessing.percentage,
          timestamp: Date.now(),
        },
      };

      const updatedCreateSiteStorageState: TCreateSiteStorageData = {
        ...createSiteStorageState,
        [locationId]: createSiteStorageState[locationId]
          ? {
              ...createSiteStorageState[locationId],
              ...payloadData,
            }
          : payloadData,
      };

      browserLocalStorage.setItem(
        ELocalStorageField.CreateSite,
        DataProcessing.serialize(updatedCreateSiteStorageState),
      );
    }
  }, [locationId, fakePerimeterProcessing.percentage]);

  const removeZonesPerimeterCalculationDataFromStorage = useCallback(() => {
    if (locationId) {
      const createSiteStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ??
        {};

      if (
        createSiteStorageState?.[locationId]?.[
          ECreateSiteLocalStorageField.CurrentZonesPerimeterCalculation
        ]
      ) {
        delete createSiteStorageState[locationId][
          ECreateSiteLocalStorageField.CurrentZonesPerimeterCalculation
        ];
        browserLocalStorage.setItem(
          ELocalStorageField.CreateSite,
          DataProcessing.serialize(createSiteStorageState),
        );
      }
    }
  }, [locationId]);

  const removeCurrentSiteFromStorage = useCallback(() => {
    if (locationId) {
      const createSiteStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ??
        {};

      if (createSiteStorageState?.[locationId]) {
        delete createSiteStorageState[locationId];

        if (Object.keys(createSiteStorageState).length) {
          browserLocalStorage.setItem(
            ELocalStorageField.CreateSite,
            DataProcessing.serialize(createSiteStorageState),
          );
        } else {
          browserLocalStorage.removeItem(ELocalStorageField.CreateSite);
        }
      }
    }
  }, [locationId]);

  const setCurrentStepToStorage = useCallback(() => {
    if (currentStep && locationId) {
      const createSiteStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ??
        {};

      const payloadData = {
        [ECreateSiteLocalStorageField.CurrentStep]: currentStep,
      };

      const updatedCreateSiteStorageState: TCreateSiteStorageData = {
        ...createSiteStorageState,
        [locationId]: createSiteStorageState[locationId]
          ? {
              ...createSiteStorageState[locationId],
              ...payloadData,
            }
          : payloadData,
      };

      browserLocalStorage.setItem(
        ELocalStorageField.CreateSite,
        DataProcessing.serialize(updatedCreateSiteStorageState),
      );
    }
  }, [locationId, currentStep]);

  const handleCreatePerimeter = () => {
    dispatch(setIsLoadingSite(true));
    dispatch(setPerimeterProcessingPercentage(0));
    return api.perimeter.createPerimeter(
      {
        bottom_right_lat: bottomRightLat,
        bottom_right_lng: bottomRightLng,
        top_left_lat: topLeftLat,
        top_left_lng: topLeftLng,
      },
      dispatch,
    );
  };

  const handleCreateUsers = () => {
    if (!roles) return;
    const addNewUsersData = convertEditInfoRolesToIAddNewUsersRequest(roles);
    if (!addNewUsersData || addNewUsersData.length === 0) return;
    api.user.addNewUsers(addNewUsersData).catch((error) => {
      toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
        autoClose: ERROR_TOAST_DELAY,
      });
    });
  };

  const handleBackClick = useCallback(() => {
    dispatch(resetCreateSite());
    // NOTE: Resilience section
    // 1. Remove current session of created site if zones are not calculated
    if (isLoadingZones) setZonesPerimeterCalculationDataToStorage();
    else removeZonesPerimeterCalculationDataFromStorage();
    // 2. Reset errors for browser storage
    dispatch(setBrowserStorageError(null));
    // 3. Reset refresh permissions in cookies
    disableStoragePersistPermission();

    dispatch(setSidebar(ESidebar.Sites));
    setCurrentStepToStorage();

    setTimeout(() => {
      dispatch(reloadMap(true));
    }, 250);
  }, [
    isLoadingZones,
    setZonesPerimeterCalculationDataToStorage,
    removeZonesPerimeterCalculationDataFromStorage,
    disableStoragePersistPermission,
    setCurrentStepToStorage,
    dispatch,
  ]);

  const handleStepChange = useCallback(
    (value: number) => {
      dispatch(setCurrentStep(value + 1));
      dispatch(reloadMap(true));
    },
    [dispatch],
  );

  const handleCloseDeleteSiteModal = () => {
    setIsOpenDeleteSiteModal(false);
  };
  const handleOpenDeleteSiteModal = () => setIsOpenDeleteSiteModal(true);

  const handleCloseRequestFlightPlanApprovalModal = () => setIsRequestFlightPlanApproval(false);
  const handleOpenRequestFlightPlanApprovalSiteModal = () => setIsRequestFlightPlanApproval(true);

  // TODO: need to abort zones perimeter jobs if site is deleted
  const handleDeleteSite = () => {
    if (!locationId) {
      handleResetCreateSite();
      return;
    }

    api.location
      .deleteById(locationId)
      .then(() => {
        handleResetCreateSite();
        // NOTE: Resilience section
        disableStoragePersistPermission();
        removeCurrentSiteFromStorage();
        dispatch(removeCurrentSiteFromStorage);
        dispatch(setBrowserStorageError(null));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.detail ?? t('errors.siteDeleteFail', { locationId: locationId }),
          { autoClose: ERROR_TOAST_DELAY },
        );
      });
  };

  const handleRequestFlightPlanApproval = async () => {
    const promises = programs.map((program) => {
      return api.program.updateProgramById(program.program_id, {
        name: program.name,
        custom_inspection_plan_requested: program.custom_inspection_plan_requested,
        custom_overview_plan_requested: program.custom_overview_plan_requested,
      });
    });

    try {
      await Promise.all(promises);
      if (locationId) {
        api.location.updateById(locationId, {
          location_status: ELocationStatus.WaitingForApproval,
        });
      }

      handleCloseRequestFlightPlanApprovalModal();
      handleResetCreateSite();
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
      handleCloseRequestFlightPlanApprovalModal();
    }
  };

  const handleResetCreateSite = () => {
    setIsOpenDeleteSiteModal(false);
    dispatch(setPerimeterProcessingPercentage(null));
    dispatch(resetCreateSite());
    dispatch(setSidebar(ESidebar.Sites));
  };

  const editableOptions: Array<{
    head: JSX.Element;
    body: JSX.Element;
    disabled: boolean;
    id: string;
  }> = useMemo(() => {
    return [
      {
        id: ESiteEditSections.Name,
        disabled: false,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.name.title')} />,
        body: <NameCreate />,
      },
      {
        id: ESiteEditSections.Perimeter,
        disabled:
          !(perimeters.length > 0) ||
          name === '' ||
          Boolean(validateSiteName(name)) ||
          isLoadingZones ||
          programs.length > 0,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.perimeter.title')} />,
        body: <PerimeterPanel />,
      },
      {
        id: ESiteEditSections.NoFlyZones,
        disabled:
          !(perimeters.length > 0) ||
          name === '' ||
          Boolean(validateSiteName(name)) ||
          isLoadingZones ||
          programs.length > 0,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.noFlyZones.title')} />,
        body: <NoFlyZonesPanel />,
      },
      {
        id: ESiteEditSections.Obstacles,
        disabled:
          !(perimeters.length > 0) ||
          name === '' ||
          Boolean(validateSiteName(name)) ||
          isLoadingZones ||
          programs.length > 0,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.obstacles.title')} />,
        body: <ObstaclesPanel />,
      },
      {
        id: ESiteEditSections.Picture,
        disabled: !(perimeters.length > 0) || name === '',
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.picture.title')} />,
        body: <PictureCreate />,
      },
      {
        id: ESiteEditSections.InspectionFrequency,
        disabled: !(perimeters.length > 0) || name === '',
        head: (
          <AccordionHeadOption title={t('sidebar.site.edit.sections.inspectionFrequency.title')} />
        ),
        body: <InspectionFrequencyCreate />,
      },
      {
        id: ESiteEditSections.Roles,
        disabled: !(perimeters.length > 0) || name === '',
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.roles.title')} />,
        body: <RolesCreate />,
      },
      {
        id: ESiteEditSections.Zones,
        disabled: !(perimeters.length > 0) || name === '' || !programs || programs.length === 0,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.zones.title')} />,
        body: <ZonesCreate />,
      },
    ].map((accordionItem, index) => ({
      ...accordionItem,
      disabled: index >= maxCurrentStep ? true : accordionItem.disabled,
    }));
  }, [perimeters, programs, name, maxCurrentStep, isLoadingZones]);

  const stepsNames = [
    t('sidebar.site.create.steps.location'),
    t('sidebar.site.edit.sections.name.title'),
    t('sidebar.site.edit.sections.perimeter.title'),
    t('sidebar.site.edit.sections.noFlyZones.title'),
    t('sidebar.site.edit.sections.obstacles.title'),
    t('sidebar.site.edit.sections.picture.title'),
    t('sidebar.site.edit.sections.inspectionFrequency.title'),
    t('sidebar.site.edit.sections.roles.title'),
    t('sidebar.site.edit.sections.zones.title'),
  ];

  const getStep = () => {
    if (currentStep < 1) return <SearchLocationStep handleNextStep={handleNextStep} />;

    return (
      <Accordion
        as='li'
        currentStep={currentStep - 1}
        onStepChange={handleStepChange}
        arrowSettings={{
          position: 'before',
          direction: 'right',
          className: styles.accordionArrow,
        }}
        className={styles.accordionItem}
        options={editableOptions}
      />
    );
  };

  const isBackDisabled = () => {
    if (networkConnection.status === EConnectionStatus.Offline) return true;
  };

  const isCancelDisabled = () => {
    if (networkConnection.status === EConnectionStatus.Offline) return true;
  };

  const isContinueDisabled = () => {
    if (networkConnection.status === EConnectionStatus.Offline) return true;

    switch (currentStep) {
      case ECreateSiteSteps.Name: {
        return isOneValueValid(!createSiteLat, !bottomRightLng, !name, validateSiteName(name));
      }
      case ECreateSiteSteps.Perimeter: {
        return name === '' || !(perimeters.length > 0);
      }
      case ECreateSiteSteps.NoFlyZones: {
        return noFlyZones.some((noFlyZone) => noFlyZone?.perimeter?.coordinates?.length === 0);
      }
      case ECreateSiteSteps.Obstacles: {
        return obstacles.some(
          (obstacle) =>
            typeof obstacle.altitude !== 'number' ||
            isNaN(obstacle.altitude) ||
            obstacle?.perimeter?.coordinates?.length === 0,
        );
      }
      case ECreateSiteSteps.Roles: {
        if (roles) {
          if (programs.length === 0) return true;
          // TODO:
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { admin, editor, viewer, inspector } = roles;
          const allRoles = [...admin, ...editor, ...viewer, ...inspector];
          return allRoles.some((role) => role.errorMessage !== null);
        }
        break;
      }
      case ECreateSiteSteps.Zones: {
        return programs.some((program) => !program.isViewed);
      }
    }

    return false;
  };

  const handleFakePercentage = useCallback(
    (zonesCalculationData: IZonesPerimeterStorageData | null): IFakePerimeterProcessing => {
      const currentPercentage = zonesCalculationData
        ? getFakePercentage({
            currentCalculationTime: zonesCalculationData.timestamp,
            currentFakePercentage: zonesCalculationData.percentage,
          })
        : 0;
      const zonesCalculationCurrentStep = Math.round(currentPercentage / FAKE_PERCENTAGE_STEP);
      return { percentage: currentPercentage, currentStep: zonesCalculationCurrentStep };
    },
    [],
  );

  const updateStateOnRefresh = useCallback(() => {
    // NOTE: set error if page is refreshed and app returned to the "Sites" mode
    if (currentBrowserStorageRef.current?.error.status) {
      updateStoragePersistPermission({
        isPermissionGranted: true,
        state: {
          mergeStrategy: EMergeStrategy.RehydratePartial,
          keyPaths: ['accessControl.browserStorage.error'],
          shouldResetPermissionAfterMerge: true,
        },
      });
      return;
    }

    setCurrentStepToStorage();

    switch (currentStep) {
      case ECreateSiteSteps.SearchCity:
        // NOTE: set manually "perimeterProcessingPercentage" in order to reset "0" to null
        // FIXME: temporary solution (revert persistor Cache issue)
        if (persistor.status === EPersistorStatus.Active) {
          persistor.unsubscribeFromStore();
          persistor.persist(
            {
              createSite: {
                [ECreateSiteSections.Perimeter]: {
                  processingPercentage: null,
                },
              },
            },
            { strategy: EMergeStrategy.PersistPartial },
          );
        }
        break;
      case ECreateSiteSteps.Name:
        // NOTE: reset manually "currentStep" to the previous step, "perimeterProcessingPercentage"
        if (isLoadingSite) {
          // FIXME: temporary solution (revert persistor Cache issue)
          if (persistor.status === EPersistorStatus.Active) {
            persistor.unsubscribeFromStore();
            persistor.persist(
              {
                createSite: {
                  steps: {
                    current: ECreateSiteSteps.SearchCity,
                  },
                  [ECreateSiteSections.Perimeter]: {
                    processingPercentage: null,
                  },
                },
              },
              { strategy: EMergeStrategy.PersistPartial },
            );
          }
        }
        break;
    }
  }, [currentStep, isLoadingSite, setCurrentStepToStorage]);

  // NOTE: use case (common): set cookie permission to rehydrate saved data with state
  useEffect(() => {
    enableStoragePersistPermission();
  }, []);

  // NOTE: use case (zone perimeter calculation): remove saved calculationData for current site (perimeter is finished)
  useEffect(() => {
    if (programs.length) removeZonesPerimeterCalculationDataFromStorage();
  }, [programs.length]);

  // NOTE: use case (common): refresh page
  useEffect(() => {
    window.addEventListener('beforeunload', updateStateOnRefresh);
    return () => {
      window.removeEventListener('beforeunload', updateStateOnRefresh);
    };
  }, [updateStateOnRefresh]);

  // TODO: need to update the implementation
  // NOTE: use case (site perimeter - regained connection): send request if connection is restored
  useEffect(() => {
    const shouldSendSitePerimeterRequestAfterRegainedConnection = areAllValuesValid(
      networkConnection.statusPrevious === EConnectionStatus.Offline,
      networkConnection.status === EConnectionStatus.Online, // NOTE: is back connection
      networkConnection.isVisible,
      currentStep === ECreateSiteSteps.SearchCity,
    );

    if (shouldSendSitePerimeterRequestAfterRegainedConnection) handleCreatePerimeter();
  }, [
    networkConnection.status,
    networkConnection.statusPrevious,
    networkConnection.isVisible,
    currentStep,
  ]);

  // NOTE: use case (site perimeter - calculation)
  // Update current step if perimeter is calculated
  useEffect(() => {
    const isChangedStepFromSearchCityToName = areAllValuesValid(
      currentStep === ECreateSiteSteps.SearchCity,
      perimeterProcessingPercentage === 100,
      !isLoadingSite,
    );

    if (isChangedStepFromSearchCityToName) {
      dispatch(setCurrentStep(ECreateSiteSteps.Name));
    }
  }, [currentStep, perimeterProcessingPercentage, isLoadingSite]);

  // NOTE:
  // 1. use case (zones perimeter calculation)
  // Return from "Sites" to selected zone where zones perimeter is processing
  useEffect(() => {
    if (isLoadingZones) {
      // 2. use case (zones perimeter - refresh page)
      // Send request if page is refreshed
      if (document.readyState !== 'complete') dispatch(loadZones());

      const savedZonesCalculationData = getZonesPerimeterCalculationDataFromStorage();
      if (savedZonesCalculationData) {
        const fakePerimeterData = handleFakePercentage(savedZonesCalculationData);
        dispatch(setFakePerimeterProcessing(fakePerimeterData));
      }
    }
  }, [isLoadingZones, getZonesPerimeterCalculationDataFromStorage, dispatch]);

  // NOTE: use case (zones perimeter - regained connection)
  // Send request if connection is restored - online
  useEffect(() => {
    const shouldUpdateZonesAfterRegainedConnection = areAllValuesValid(
      networkConnection.statusPrevious === EConnectionStatus.Offline,
      networkConnection.status === EConnectionStatus.Online, // NOTE: is back connection
      networkConnection.isVisible,
      isLoadingZones,
    );

    if (shouldUpdateZonesAfterRegainedConnection) {
      const savedZonesCalculationData = getZonesPerimeterCalculationDataFromStorage();

      dispatch(loadZones());
      if (savedZonesCalculationData) {
        const fakePerimeterData = handleFakePercentage(savedZonesCalculationData);
        dispatch(setFakePerimeterProcessing(fakePerimeterData));
      }
    }
  }, [
    networkConnection.statusPrevious,
    networkConnection.status,
    networkConnection.isVisible,
    isLoadingZones,
  ]);

  // NOTE: use case (site/zones perimeter - disable logout)
  // Disable logout when calculated perimeter site or generated zones
  useEffect(() => {
    const isDisabledLogout = isLoadingSite || isLoadingZones;
    dispatch(setIsDisabledLogout(isDisabledLogout));
  }, [isLoadingZones, isLoadingSite]);

  const getContinueButton = () => {
    const commonProps = {
      disabled: isContinueDisabled(),
      onClick: handleNextStep,
    };

    let buttonText: string;
    let buttonColor: EButtonColor;

    if (currentStep === ECreateSiteSteps.Obstacles && programs.length === 0) {
      buttonText = t('buttons.generateZones');
      buttonColor = EButtonColor.Green;
    } else if (currentStep === ECreateSiteSteps.Zones) {
      buttonText = t('buttons.confirmSite');
      buttonColor = EButtonColor.Blue;
    } else {
      buttonText = t('buttons.continue');
      buttonColor = EButtonColor.Blue;
    }

    return (
      <Button color={buttonColor} {...commonProps}>
        {buttonText}
      </Button>
    );
  };

  const headerTitleName = useMemo(() => {
    const errorMessage = validateSiteName(name);

    return isOneValueValid(errorMessage, name === '', maxCurrentStep < ECreateSiteSteps.Perimeter)
      ? t('sidebar.site.create.title')
      : name;
  }, [name, maxCurrentStep]);

  return (
    <div className='h-full'>
      <Modal
        title={t('sidebar.site.edit.modals.delete')}
        isOpen={isOpenDeleteSiteModal}
        onClose={handleCloseDeleteSiteModal}
        onDecline={handleCloseDeleteSiteModal}
        onConfirm={handleDeleteSite}
      />
      <Modal
        title={t('sidebar.site.edit.modals.requestFlightPlanApproval')}
        isOpen={isRequestFlightPlanApproval}
        onClose={handleCloseRequestFlightPlanApprovalModal}
        onDecline={handleCloseRequestFlightPlanApprovalModal}
        onConfirm={handleRequestFlightPlanApproval}
      />
      <Navbar
        header={headerTitleName}
        headerClassName={'text-outflier-white text-2xl font-semibold leading-6 tracking-tight'}
        subheader={
          t('sidebar.site.create.subHeader', {
            currentStep: currentStep + 1,
            stepName: stepsNames[currentStep],
            steps: NUMBER_OF_STEPS,
          }) ?? ''
        }
        subheaderClassName={'text-outflier-white text-lg font-medium leading-5'}
        onBackClick={handleBackClick}
        isDisabledBack={isBackDisabled()}
        switcher={false}
      />

      <div className='h-[calc(100%-2.8125rem-71px)] overflow-auto hide-scrollbar'>
        <div className='flex flex-col overflow-auto pb-4'>{getStep()}</div>
      </div>

      <div className='mt-1 flex gap-1'>
        <Button
          color={EButtonColor.Red}
          onClick={handleOpenDeleteSiteModal}
          disabled={isCancelDisabled()}
        >
          {t('buttons.cancel')}
        </Button>
        {getContinueButton()}
      </div>
    </div>
  );
};

export default SiteCreation;
