import axios from 'axios';
import { createBearerToken } from '@core/utils';

interface IRequestGetViewerParams {
  hostname: string;
  access_token: string;
  reservation_token: string;
}

interface IResponseGetViewerParams {
  aspect_ratio: number;
  vfov: number;
}

export const getViewerParameters = async ({
  hostname,
  access_token,
  reservation_token,
}: IRequestGetViewerParams): Promise<IResponseGetViewerParams> => {
  return axios(`https://${hostname}/viewer_parameters`, {
    method: 'GET',
    headers: {
      Authorization: createBearerToken(access_token),
      token: reservation_token,
    },
  }).then((res) => res.data);
};
