import { useTranslation } from 'react-i18next';
import { useDispatchTyped } from '@core/hooks';
import { setImage, useCreateSitePicture } from '@core/store/slices';
import { isNull } from '@core/utils/guards/isNull';
import { isString } from '@core/utils/guards/isString';
import { fileToBase64 } from '@core/utils/processing/file';
import PictureBody from './PictureBody';

const PictureCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const { image, imageUrl } = useCreateSitePicture();

  const handleUploadImage = async (file: File | undefined) => {
    if (file instanceof File) {
      let imageBase64: string | ArrayBuffer | null = null;
      try {
        imageBase64 = await fileToBase64(file);
      } finally {
        if (isString(imageBase64) || isNull(imageBase64)) {
          dispatch(setImage(imageBase64));
        }
      }
    }
  };

  const handleDeleteImage = () => {
    dispatch(setImage(null));
  };

  return (
    <>
      <PictureBody
        text={{
          notification: t('sidebar.site.edit.sections.picture.notification'),
          upload: t('sidebar.site.edit.sections.picture.addText'),
        }}
        picture={{ value: image, errorMessage: null }}
        pictureUrl={imageUrl}
        onUpload={handleUploadImage}
        onDelete={handleDeleteImage}
      />
    </>
  );
};

export default PictureCreate;
