// TODO:

/* eslint-disable no-case-declarations */

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Canvas } from '@react-three/fiber';
import _ from 'lodash';
import * as THREE from 'three';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { EMapViewerActive, ESidebar, EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ICamera } from '@core/interfaces/model';
import {
  IModelFlags,
  addSocketConnection,
  removeSocketConnection,
  removeWebRTCConnection,
  setPreloader,
  useModel3DFlagsSelector,
  useModel3DSocketConnectionSelector,
  useSidebarSelector,
} from '@core/store/slices';
import { areAllValuesValid } from '@core/utils';
import { toFixedNumber } from '@core/utils/formatting/numberFormat';
import { useMainContext } from '@modules/Layout/contexts/main';
import Scene from './components/Scene';
import { PRECISION } from './constants';
import { ESocketMessageType, ESocketType } from './enums';
import WebSocketClient from './network/websocketClient';
import { debounceFn } from './utils/debounceFn';
import { getSelectedAnomalyCameraPosition } from './utils/getSelectedAnomalyCameraPosition';
import { useModelViewerContext } from '../../contexts/modelViewer';
import { ICameraProps } from '../../interfaces/camera';
import { IDrawable } from '../../interfaces/drawable';
import { IModelState } from '../../interfaces/model';
import { IModelSettings } from '../../interfaces/settings';

// Use z-up coordinate system by default
THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1);

// Enable caching for GLTF loader
THREE.Cache.enabled = true;

// Use three-mesh-bvh to speed up raycasting
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

function arePropsEqual(prevProps, nextProps) {
  const isHideDrawablesChanged = prevProps.hide_drawables !== nextProps.hide_drawables;
  if (isHideDrawablesChanged) {
    return false;
  }

  const theSameSettings = _.isEqual(prevProps.settings, nextProps.settings);
  if (!theSameSettings) {
    return false;
  }

  const theSameCurrentCamera = _.isEqual(prevProps.currentCamera, nextProps.currentCamera);
  if (nextProps.currentCamera !== null && !theSameCurrentCamera) {
    return false;
  }

  const theSameNextCameraCenter = _.isEqual(prevProps.nextCameraCenter, nextProps.nextCameraCenter);
  if (!theSameNextCameraCenter) {
    return false;
  }

  const theSameInitCamera = _.isEqual(prevProps.initCamera, nextProps.initCamera);
  if (nextProps.initCamera !== null && !theSameInitCamera) {
    return false;
  }

  const theSameDatasetCameraPoses = _.isEqual(
    prevProps.datasetCameraPoses,
    nextProps.datasetCameraPoses,
  );
  if (nextProps.datasetCameraPoses !== null && !theSameDatasetCameraPoses) {
    return false;
  }

  const theSameDrawables = _.isEqual(prevProps.drawables, nextProps.drawables);
  if (nextProps.drawables !== null && !theSameDrawables) {
    return false;
  }

  const theSameSelectedDrawables = _.isEqual(
    prevProps.selectedDrawables,
    nextProps.selectedDrawables,
  );
  if (nextProps.selectedDrawables !== null && !theSameSelectedDrawables) {
    return false;
  }

  const theSamePointCloudSrc = _.isEqual(prevProps.pointcloudSrc, nextProps.pointcloudSrc);
  if (nextProps.pointcloudSrc !== null && !theSamePointCloudSrc) {
    return false;
  }

  const theSameReportsData = _.isEqual(prevProps.reportsData, nextProps.reportsData);
  if (nextProps.reportsData !== null && !theSameReportsData) {
    return false;
  }

  const isViewerChanged = prevProps.viewer !== nextProps.viewer;
  if (isViewerChanged) {
    return false;
  }

  const isWidthChanged = prevProps.width !== nextProps.width;
  if (isWidthChanged) {
    return false;
  }

  const isHeightChanged = prevProps.height !== nextProps.height;
  if (isHeightChanged) {
    return false;
  }

  const theSameCameraProps = _.isEqual(prevProps.cameraProps, nextProps.cameraProps);
  if (nextProps.cameraProps !== null && !theSameCameraProps) {
    return false;
  }

  const theSameOnHideDrawables = _.isEqual(prevProps.onHideDrawables, nextProps.onHideDrawables);
  if (nextProps.onHideDrawables !== null && !theSameOnHideDrawables) {
    return false;
  }

  const theSameOnShowDrawables = _.isEqual(prevProps.onShowDrawables, nextProps.onShowDrawables);
  if (nextProps.onShowDrawables !== null && !theSameOnShowDrawables) {
    return false;
  }

  const theSameOnClickAnomaly = _.isEqual(prevProps.onClickAnomaly, nextProps.onClickAnomaly);
  if (nextProps.onClickAnomaly !== null && !theSameOnClickAnomaly) {
    return false;
  }

  const theSameOnSelectedSample = _.isEqual(prevProps.onSelectedSample, nextProps.onSelectedSample);
  if (nextProps.onSelectedSample !== null && !theSameOnSelectedSample) {
    return false;
  }

  const isImageSrcChanged = prevProps.imageSrc !== nextProps.imageSrc;
  if (isImageSrcChanged) {
    return false;
  }

  const theSameIceServers = _.isEqual(prevProps.ice_servers, nextProps.ice_servers);
  if (!theSameIceServers) {
    return false;
  }

  const isServerHostChanged = prevProps.server_host !== nextProps.server_host;
  if (isServerHostChanged) {
    return false;
  }

  const isServerPortChanged = prevProps.server_port !== nextProps.server_port;
  if (isServerPortChanged) {
    return false;
  }

  const isServerTlsChanged = prevProps.server_tls !== nextProps.server_tls;
  if (isServerTlsChanged) {
    return false;
  }

  const isAccessTokenChanged = prevProps.access_token !== nextProps.access_token;
  if (isAccessTokenChanged) {
    return false;
  }

  const isReservationTokenChanged = prevProps.reservation_token !== nextProps.reservation_token;
  if (isReservationTokenChanged) {
    return false;
  }

  return true;
}

interface IProps {
  width: number;
  height: number;
  server_host: string | null;
  server_port: number;
  server_tls: boolean;
  ice_servers: RTCIceServer[];
  access_token: string | null;
  reservation_token: string | null;
  settings: IModelSettings;
  nextCameraCenter: any;
  currentCamera: ICamera | null;
  initCamera: ICamera | null;
  datasetCameraPoses: [number, number, number, number][] | null;
  cameraProps: ICameraProps | null;
  drawables: IDrawable[];
  selectedDrawables: IDrawable[];
  pointcloudSrc: string | null;
  imageSrc: string;
  hide_drawables: boolean;
  onHideDrawables: () => void;
  onShowDrawables: () => void;
  onStateChange: (state: Partial<IModelState>) => void;
  onClickAnomaly: (anomalyId: string) => void;
  onSelectSample: (sampleId: string) => void;
}

const ThreetsViewer: React.FC<IProps> = ({
  server_host,
  server_port,
  server_tls,
  ice_servers,
  settings: propsSettings,
  width,
  height,
  nextCameraCenter,
  currentCamera,
  initCamera,
  datasetCameraPoses,
  drawables,
  selectedDrawables,
  pointcloudSrc,
  hide_drawables,
  cameraProps,
  imageSrc,
  access_token,
  reservation_token,
  onStateChange,
  onClickAnomaly,
  onSelectSample,
  onHideDrawables,
  onShowDrawables,
}) => {
  const {
    model: { sidebarOrbitControl },
  } = useMainContext();
  const { t } = useTranslation();
  const modelFlags = useModel3DFlagsSelector();
  const socketConnection = useModel3DSocketConnectionSelector();
  const modelViewerContextValue = useModelViewerContext();
  const { sidebar } = useSidebarSelector();

  const wsRef = useRef<WebSocket | null>(null);
  wsRef.current = socketConnection?.ws ?? null;

  const modelFlagsRef = useRef<IModelFlags>(modelFlags);
  modelFlagsRef.current = modelFlags;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const dispatch = useDispatchTyped();

  const handleSocketSend = useCallback((type: ESocketType, payload: any) => {
    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) return;
    const serializedBody = JSON.stringify({ type, payload });
    wsRef.current.send(serializedBody);
  }, []);

  const handleSettingsSend = useCallback(
    (settings: IModelSettings) => {
      onStateChange({ settings });
      handleSocketSend(ESocketType.SettingsChange, settings);
    },
    [onStateChange, handleSocketSend],
  );

  // TODO: unusable function
  const handleSettingsChange = useCallback(
    (settings: IModelSettings) => {
      const theSameSettings = _.isEqual(propsSettings, settings);
      if (theSameSettings) return;

      handleSettingsSend(settings);
    },
    [propsSettings, handleSettingsSend],
  );

  const handleCameraChange = useCallback(
    (camera: ICamera) => {
      if (!hide_drawables) onHideDrawables();

      debounceFn(onShowDrawables);

      const currentCamera = {
        up: {
          x: toFixedNumber(camera.up.x, PRECISION),
          y: toFixedNumber(camera.up.y, PRECISION),
          z: toFixedNumber(camera.up.z, PRECISION),
        },
        center: {
          x: toFixedNumber(camera.center.x, PRECISION),
          y: toFixedNumber(camera.center.y, PRECISION),
          z: toFixedNumber(camera.center.z, PRECISION),
        },
        eye: {
          x: toFixedNumber(camera.eye.x, PRECISION),
          y: toFixedNumber(camera.eye.y, PRECISION),
          z: toFixedNumber(camera.eye.z, PRECISION),
        },
      };

      onStateChange({ currentCamera });
      handleSocketSend(ESocketType.CameraChange, currentCamera);
    },
    [onHideDrawables, onShowDrawables, onStateChange, handleSocketSend, hide_drawables],
  );

  const handleUpdateAnomalyCameraPosition = useCallback(
    (solarPanel: IDrawable, datasetCameraPoses: [number, number, number, number][]) => {
      const cameraView = getSelectedAnomalyCameraPosition(solarPanel, datasetCameraPoses);

      handleSocketSend(ESocketType.CameraChange, cameraView);
      onStateChange({ initCamera: cameraView });
    },
    [handleSocketSend, onStateChange],
  );

  const cleanUpSidebarScene = useCallback(() => {
    sidebarOrbitControl.current?.dispose();
    sidebarOrbitControl.current = null;
  }, []);

  const handleDrawableClick = useCallback(
    (event: any) => {
      const object = event.object;

      if (!object?.userData) return;
      const drawableId = object?.userData.id;

      // NOTE: prevent set "stopPropagation" on a selected frustum
      // because anomaly can be selected underneath the selected frustum
      if (drawableId.includes('frustum')) {
        return;
      }

      event.stopPropagation();

      if (drawableId.includes('with_anomaly')) {
        // 1. use case (click on anomaly in a sidebar):
        // Problem with closures when obviously unmounting a component (Inspection View).
        const shouldCleanUpSidebarScene = areAllValuesValid(
          modelViewerContextValue.type === EViewerType.InspectionView,
          modelViewerContextValue.activeViewer === EMapViewerActive.Sidebar,
          sidebar === ESidebar.Zone,
        );

        if (shouldCleanUpSidebarScene) {
          cleanUpSidebarScene();
        }

        // 2. use case (center camera bases on clicked anomaly)
        const anomaly = drawableId.match(/\d+/g)[1];
        const foundSolarPanel = drawables.find((drawable) => drawable.id === drawableId);

        if (foundSolarPanel && datasetCameraPoses) {
          handleUpdateAnomalyCameraPosition(foundSolarPanel, datasetCameraPoses);
        }
        onClickAnomaly(anomaly);
        return;
      }

      if (drawableId.includes('sample')) {
        const sample = drawableId.match(/\d+/g)[0];
        onSelectSample(sample);
        return;
      }

      if (drawableId.includes('flight_path_sample')) {
        return;
      }

      if (drawableId === 'flight_path') {
        return;
      }
    },
    [
      onClickAnomaly,
      onSelectSample,
      handleUpdateAnomalyCameraPosition,
      cleanUpSidebarScene,
      drawables,
      datasetCameraPoses,
      sidebar,
      modelViewerContextValue.type,
      modelViewerContextValue.activeViewer,
    ],
  );

  const handleImageDoubleClick = useCallback(
    (event: any) => {
      if (!event) {
        return onStateChange({ nextCameraCenter: null });
      }

      const target = event.nativeEvent.target as HTMLElement;

      const nextCameraCenter = {
        x: event.nativeEvent.offsetX / target.clientWidth,
        y: event.nativeEvent.offsetY / target.clientHeight,
      };

      handleSocketSend(ESocketType.NextCameraCenterPixelChange, nextCameraCenter);
    },
    [onStateChange, handleSocketSend],
  );

  const handleImageClick = useCallback(
    (event: any) => {
      handleDrawableClick(event);
    },
    [handleDrawableClick],
  );

  useEffect(() => {
    if (!server_host) return;
    // NOTE: set socket connection once on viewer main view
    if (modelViewerContextValue.type === EViewerType.InspectionView) return;

    const socket = new WebSocketClient({
      host: server_host,
      port: server_port,
      secure: server_tls,
    });

    dispatch(addSocketConnection(socket));

    // Callback handling messages sent from the rendering server
    socket.ws.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      const type = msg?.type;
      const payload = msg?.payload;

      switch (type) {
        case ESocketMessageType.InitCamera:
          if (!modelFlagsRef.current.shouldPreventSocketInitCamera) {
            onStateChange({ [ESocketMessageType.InitCamera]: payload });
          }
          break;
        case ESocketMessageType.DatasetCameraPoses:
          onStateChange({ [ESocketMessageType.DatasetCameraPoses]: payload });
          break;
        case ESocketMessageType.NextCameraCenter:
          onStateChange({ [ESocketMessageType.NextCameraCenter]: payload });
          break;
      }
    };

    socket.ws.onopen = () => handleSettingsSend(propsSettings);
    socket.ws.onclose = (event) => {
      if (event.wasClean)
        console.info(
          `Socket connection closed cleanly, code = ${event.code} reason = ${event.reason}`,
        );
      else {
        toast.error(t('errors.socketConnectionFail'), {
          autoClose: ERROR_TOAST_DELAY,
        });
      }

      dispatch(removeWebRTCConnection());
      dispatch(removeSocketConnection());

      dispatch(setPreloader(false));
    };
    socket.ws.onerror = (error) => {
      toast.error(t('errors.socketError', { error: error }));

      dispatch(removeSocketConnection());
      dispatch(removeWebRTCConnection());
      dispatch(setPreloader(false));
    };
  }, [server_host]);

  // Close socket when page is closed
  useEffect(() => {
    const handleCloseSocketConnection = () => wsRef.current?.close();

    window.addEventListener('beforeunload', handleCloseSocketConnection);
    return () => {
      window.removeEventListener('beforeunload', handleCloseSocketConnection);
    };
  }, []);

  const canvasStyles = useMemo(
    () => ({
      width: width + 'px',
      height: height + 'px',
    }),
    [width, height],
  );

  return (
    <div style={canvasStyles}>
      <Canvas
        style={canvasStyles}
        gl={{
          antialias: true,
          toneMapping: THREE.NoToneMapping,
          logarithmicDepthBuffer: true,
          precision: 'highp',
        }}
        ref={canvasRef}
        onClick={handleImageClick}
        onDoubleClick={handleImageDoubleClick}
      >
        <Scene
          width={width}
          height={height}
          imageSrc={imageSrc}
          access_token={access_token}
          reservation_token={reservation_token}
          server_host={server_host}
          server_port={server_port}
          server_tls={server_tls}
          ice_servers={ice_servers}
          initCamera={initCamera}
          drawables={drawables}
          cameraProps={cameraProps}
          nextCameraCenter={nextCameraCenter}
          hide_drawables={hide_drawables}
          onCameraChange={handleCameraChange}
          onImageClick={handleImageClick}
          onImageDoubleClick={handleImageDoubleClick}
        />
      </Canvas>
    </div>
  );
};

export default memo(ThreetsViewer, arePropsEqual);
