import { useCallback, useRef } from 'react';
import outboardLogo from '@assets/icons/outboard-logo.svg';
import { EControlMode, ESidebar, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  resetControls,
  resetModel3DState,
  setSidebar,
  setViewer,
  useControlsSelector,
} from '@core/store/slices';
import {
  useCurrentInspectionSelector,
  useDropdownVisibleInspectionsSelector,
} from '@core/store/slices/inspections';
import { Timeline } from '@components/Timeline';
import { InspectionsDate } from './components/InspectionsDate';
import { User } from './components/User';
import { IUpdateScrollPosition } from './interfaces';
import styles from './styles.scss';

export const Header = () => {
  const currentInspection = useCurrentInspectionSelector();
  const visibleInspections = useDropdownVisibleInspectionsSelector();
  const { mode } = useControlsSelector();

  const updateScrollPositionByInspectionDateRef = useRef<
    ((options: IUpdateScrollPosition) => void) | null
  >(null);
  const dispatch = useDispatchTyped();

  const setScrollPositionByInspectionDateHandler = useCallback(
    (handler: (options: IUpdateScrollPosition) => void) => {
      updateScrollPositionByInspectionDateRef.current = handler;
    },
    [],
  );

  const handleUpdateScrollPositionByInspectionDate = useCallback(
    (options: IUpdateScrollPosition) => {
      updateScrollPositionByInspectionDateRef.current?.(options);
    },
    [],
  );

  const handleHomeClick = () => {
    if (mode === EControlMode['3D']) {
      dispatch(resetModel3DState());
      dispatch(resetControls());
    }

    dispatch(setViewer(EViewer.Map));
    dispatch(setSidebar(ESidebar.Sites));
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoWrapper}>
        <button className={styles.logoBtn} onClick={handleHomeClick}>
          <img className={styles.logo} src={outboardLogo} alt='outflier logo' />
        </button>
      </div>
      <div className={styles.timelineWrapper}>
        <Timeline onSetScrollPosition={setScrollPositionByInspectionDateHandler} />
      </div>
      <div className={styles.dateDropdownWrapper}>
        <InspectionsDate
          onUpdateScrollPosition={handleUpdateScrollPositionByInspectionDate}
          toCompare={false}
          inspection={currentInspection}
          inspectionsOptions={visibleInspections}
        />
      </div>
      <div className={styles.userDropdownWrapper}>
        <User />
      </div>
    </header>
  );
};
