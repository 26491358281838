export function getTransformedDatasetCameraPoses(datasetCameraPoses: any) {
  return datasetCameraPoses.map((cameraPose) =>
    cameraPose.slice(0, 3).reduce((acc, pose) => [...acc, pose[3]], []),
  );
}

export function getTheBestPoseDatasetCameraPose(datasetCameraPoses: any, bestPoseIdx: number) {
  return datasetCameraPoses[bestPoseIdx]
    .slice(0, 3)
    .reduce((acc, cameraPose) => [...acc, cameraPose[3]], []);
}
