import React from 'react';
import cn from 'classnames';
import centerIcon from '@assets/icons/center.svg';
import minusIcon from '@assets/icons/minus.svg';
import pathIcon from '@assets/icons/path.svg';
import plusIcon from '@assets/icons/plus.svg';
import solarPanelsIcon from '@assets/icons/solar-panels.svg';
import { EControlMode, ESidebar, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  resetModel3DState,
  setMode,
  setShouldCalculatedInitCameraPosition,
  setShouldPreventSocketInitCamera,
  setShouldUpdateCenterAnomaly,
  toggleFlightPath,
  toggleSolarPanels,
  useControlsSelector,
  useSidebarSelector,
  useViewerSelector,
} from '@core/store/slices';
import { Control } from './components/Control';
import { ScaleControl } from './components/ScaleControl';
import styles from './styles.scss';

interface IProps {
  show?: boolean;
  isShownControlMode?: boolean;
  scale?: string | null;
  isSolarPanelsDisabled?: boolean;
  isFlightPathDisabled?: boolean;
  is3DModeDisabled?: boolean;
  onDynamicZoom?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

export const Controls: React.FC<IProps> = React.memo(
  ({
    show = true,
    isShownControlMode = false,
    scale,
    isSolarPanelsDisabled = false,
    isFlightPathDisabled = false,
    is3DModeDisabled = false,
    onDynamicZoom,
    onZoomIn,
    onZoomOut,
  }) => {
    if (!show) return null;

    const controls = useControlsSelector();
    const { sidebar } = useSidebarSelector();
    const { viewer } = useViewerSelector();
    const dispatch = useDispatchTyped();

    const showTopLeftControls = [ESidebar.Zone, ESidebar.Anomaly].includes(sidebar);
    const showBottomLeftControls = [EViewer.Map, EViewer.CompareMap, EViewer.Model].includes(
      viewer,
    );
    const showBottomRightControls = [EViewer.Map, EViewer.CompareMap].includes(viewer);

    const handleToggleMode = (mode: EControlMode) => {
      switch (mode) {
        case EControlMode['2D']:
          dispatch(resetModel3DState());
          break;
        case EControlMode['3D']:
          switch (sidebar) {
            case ESidebar.Zone: {
              dispatch(setShouldCalculatedInitCameraPosition(true));
              dispatch(setShouldPreventSocketInitCamera(true));
              break;
            }
            case ESidebar.Anomaly: {
              dispatch(setShouldUpdateCenterAnomaly(true));
              dispatch(setShouldPreventSocketInitCamera(true));
              break;
            }
          }
          break;
      }
      dispatch(setMode(mode));
    };
    const handleToggleSolarPanels = () => dispatch(toggleSolarPanels());
    const handleTogglePath = () => dispatch(toggleFlightPath());

    return (
      <div>
        <div className={cn(styles.topLeft, { [styles.show]: showTopLeftControls })}>
          {isShownControlMode && (
            <div className={styles.controlsWrapper}>
              <Control
                className={styles.firstIcon}
                active={controls.mode === EControlMode['2D']}
                text={EControlMode['2D']}
                onClick={handleToggleMode.bind(this, EControlMode['2D'])}
              />
              <Control
                className={styles.secondIcon}
                active={controls.mode === EControlMode['3D']}
                disabled={controls.isCompareMode || is3DModeDisabled}
                text={EControlMode['3D']}
                onClick={
                  controls.isCompareMode
                    ? undefined
                    : handleToggleMode.bind(this, EControlMode['3D'])
                }
              />
            </div>
          )}
          <Control
            icon={solarPanelsIcon}
            active={controls.hasSolarPanels}
            disabled={isSolarPanelsDisabled}
            onClick={handleToggleSolarPanels}
          />
          <Control
            icon={pathIcon}
            active={controls.hasFlightPath}
            disabled={isFlightPathDisabled}
            onClick={handleTogglePath}
          />
        </div>
        <div className={cn(styles.bottomLeft, { [styles.show]: showBottomLeftControls })}>
          <Control icon={centerIcon} onClick={onDynamicZoom} />
          <div className={styles.controls}>
            <Control className={styles.firstIcon} icon={plusIcon} onClick={onZoomIn} />
            <Control className={styles.secondIcon} icon={minusIcon} onClick={onZoomOut} />
          </div>
        </div>
        <div className={cn(styles.bottomRight, { [styles.show]: showBottomRightControls })}>
          <ScaleControl scale={scale} />
        </div>
      </div>
    );
  },
);
