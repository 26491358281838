export enum ESiteEditSections {
  Name = 'name',
  Perimeter = 'perimeter',
  NoFlyZones = 'noFlyZones',
  Obstacles = 'obstacles',
  Picture = 'picture',
  InspectionFrequency = 'inspectionFrequency',
  Roles = 'roles',
  Zones = 'zones',
}

export enum ESiteEditActions {
  AddOne = 'addOne',
  UpdateOne = 'updateOne',
  DeleteOne = 'deleteOne',
  SelectOne = 'selectOne',
  ResetOne = 'resetOne',
  SelectPreviousZone = 'selectPreviousZone',
  SelectNextZone = 'selectNextZone',
}
