import React from 'react';
import { useDispatchTyped } from '@core/hooks';
import { IMapboxCityData } from '@core/interfaces/createSite';
import {
  setLat,
  setLng,
  setSelectedLocation,
  useCreateSiteSelectedLocation,
} from '@core/store/slices';
import MapboxSearch from '@modules/Sidebar/views/CreateSite/components/SearchLocation/MapboxSearch';

interface IProps {
  handleNextStep: () => void;
}

const SearchLocationStep: React.FC<IProps> = () => {
  const { selectedLocation } = useCreateSiteSelectedLocation();
  const dispatch = useDispatchTyped();

  const handleCitySelect = (data: IMapboxCityData) => {
    const { coordinates } = data;
    const [lng, lat] = coordinates;

    if (lat) dispatch(setLat(lat));
    if (lng) dispatch(setLng(lng));

    dispatch(setSelectedLocation(data));
  };

  return (
    <>
      <h1 className='text-base text-outflier-yellow'>Center the map on your site</h1>
      <MapboxSearch onCitySelect={handleCitySelect} selectedLocation={selectedLocation} />
    </>
  );
};

export default SearchLocationStep;
