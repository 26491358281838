/* eslint-disable no-console */

/* eslint-disable no-case-declarations */
import mapboxgl from 'mapbox-gl';
import { dynamicZooms } from '@core/constants/mapbox';
import { ESidebar } from '@core/enums';
import { EAnomalyStatus, IAnomaly } from '@core/interfaces/anomaly';
import {
  MapClickEvent,
  MapMouseEnterEvent,
  MapMouseLeaveEvent,
  MapMouseMoveEvent,
} from '@core/interfaces/map';
import { IReport } from '@core/interfaces/report';
import { getAnomaliesFeatures } from './getAnomaliesFeatures';
import { handleZoomToAnomaly } from './handleZoomToAnomaly';
import { setFeatureCollectionData } from './mapboxConfig';
import { removeAnomaliesHandlers } from './removeAnomaliesHandlers';
import { setAnomalies } from './setAnomalies';
import { setAnomaliesHandlers } from './setAnomaliesHandlers';
import { EAnomalyLayers, EZonesLayers } from '../../enums/layers';
import { EAnomalySources } from '../../enums/sources';
import { removeLayersAndSources } from '../removeLayersAndSources';

export interface IAnomaliesProps {
  map: mapboxgl.Map | null;
  report: IReport | null;
  selectedAnomaly?: IAnomaly;
  color?: string;
  sidebar: ESidebar;
  anomaliesType?: EAnomalyStatus | null;
  onClick?: (event: MapClickEvent) => void;
  onMouseLeave?: (event: MapMouseLeaveEvent) => void;
  onMouseEnter?: (event: MapMouseEnterEvent) => void;
  onMouseMove?: (event: MapMouseMoveEvent) => void;
  isCompareMode: boolean;
  withZoomToAnomaly?: boolean;
}

let initCompareMode = false;
let initAnomaly: IAnomaly | null = null;

export const addAnomalies = ({
  map,
  report,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  ...restProps
}: IAnomaliesProps) => {
  if (!map) return;

  const {
    sidebar,
    selectedAnomaly,
    anomaliesType,
    isCompareMode,
    withZoomToAnomaly = true,
  } = restProps;

  if (initCompareMode !== isCompareMode) {
    removeAnomaliesHandlers(map, {
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
    });
  }

  switch (sidebar) {
    case ESidebar.Sites:
    case ESidebar.Site:
      removeLayersAndSources(map, {
        [EAnomalySources.Anomalies]: [EAnomalyLayers.Anomalies, EAnomalyLayers.AnomaliesBorder],
      });
      removeAnomaliesHandlers(map, {
        onClick,
        onMouseEnter,
        onMouseLeave,
        onMouseMove,
      });
      break;
    case ESidebar.Zone:
    case ESidebar.Anomaly:
      // Anomaly section
      const isInitZoom = !!(!initAnomaly && selectedAnomaly);

      if (selectedAnomaly?.id && withZoomToAnomaly) {
        handleZoomToAnomaly(map, selectedAnomaly, {
          isCompareMode,
          isInitialZoom: isInitZoom,
          zoom: dynamicZooms[ESidebar.Anomaly],
        });
      }

      // Zone section
      if (!report) {
        removeLayersAndSources(map, {
          [EAnomalySources.Anomalies]: [EAnomalyLayers.Anomalies, EAnomalyLayers.AnomaliesBorder],
        });
        removeAnomaliesHandlers(map, {
          onClick,
          onMouseEnter,
          onMouseLeave,
          onMouseMove,
        });
      }
      // Common section
      if (report?.anomalies) {
        const anomaliesFeatures = getAnomaliesFeatures({ report, anomaliesType, selectedAnomaly });

        if (map.getSource(EAnomalySources.Anomalies)) {
          (map.getSource(EAnomalySources.Anomalies) as mapboxgl.GeoJSONSource).setData(
            setFeatureCollectionData(anomaliesFeatures),
          );
        } else {
          const shouldSetAnomalies =
            map.getLayer(EZonesLayers.PolygonBorder) &&
            !(
              map.getLayer(EAnomalyLayers.Anomalies) || map.getLayer(EAnomalyLayers.AnomaliesBorder)
            );

          if (shouldSetAnomalies) setAnomalies(map, anomaliesFeatures);
        }
      }

      report &&
        setAnomaliesHandlers(map, {
          onClick,
          onMouseEnter,
          onMouseLeave,
          onMouseMove,
        });
      break;
  }

  initAnomaly = selectedAnomaly ?? null;
  initCompareMode = isCompareMode;
};
