/* eslint-disable no-prototype-builtins */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import { Feature } from '@turf/turf';
import { FeatureCollection } from 'geojson';
import { debounce, throttle } from 'lodash';
import mapboxgl, { LngLatBoundsLike, MapEventType } from 'mapbox-gl';
import { INITIAL_CREATE_SITE_COORDINATES } from '@core/constants/createSite';
import {
  CREATE_SITE_ZOOM,
  dynamicZooms,
  mapZooms,
  MIN_ZOOM_FOR_ONE_SITE,
  REGULAR_STYLE_URL,
  SATELLITE_STYLE_URL,
  THRESHOLD_ZOOM_IN_FOR_ONE_SITE_FROM_SEVERALS,
  ZoomRange,
} from '@core/constants/mapbox';
import { EMapViewerActive, ESidebar, EViewer, EViewerType } from '@core/enums';
import { ECreateSiteSections } from '@core/enums/createSite';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { useMapbox } from '@core/hooks/useMapbox';
import { ELocationStatus, IProgram, ISample, ISite } from '@core/interfaces';
import { INoFlyZone, IObstacle } from '@core/interfaces/createSite';
import {
  IMapConfig,
  MapClickEvent,
  MapMouseEnterEvent,
  MapMouseLeaveEvent,
  MapMouseMoveEvent,
} from '@core/interfaces/map';
import { IReport } from '@core/interfaces/report';
import { DataProcessing } from '@core/services';
import {
  addSelectedSiteInfo,
  continueCreation,
  setCurrentSiteIdAndUpdatePrograms,
  setPerimeterGpsBoundaries,
  setPreloader,
  setSidebar,
  setSidebarViewer,
  setViewer,
  updateCurrentNoFlyZonePerimeter,
  updateCurrentObstaclePerimeter,
  updatePerimeter,
  updateSitesSidebarAndCurrentInspectionId,
  useControlsSelector,
  useCreateSiteSelectedLocation,
  useCreateSiteSteps,
  useCreateSiteZones,
  useCurrentCreateSiteProgramSelector,
  useCurrentNoFlyZoneSelector,
  useCurrentObstacleSelector,
  useCurrentPerimeterSelector,
  useCurrentSiteSelector,
  useNewSiteLocationCoordinates,
  useSidebarSelector,
  useSitesSelector,
  useViewerSelector,
} from '@core/store/slices';
import {
  setCurrentAnomalyId,
  useAnomaliesSelector,
  useCurrentAnomalySelector,
} from '@core/store/slices/anomalies';
import {
  setCurrentInspectionIdByProgramId,
  updateCurrentInspectionId,
  useInspectionsSelector,
  useVisibleInspectionsSelector,
} from '@core/store/slices/inspections';
import {
  reloadMap,
  setIsDynamicZoom,
  setPreviousZoom,
  useMapSelector,
} from '@core/store/slices/map';
import {
  setCurrentProgramId,
  useCurrentProgramSelector,
  useProgramsForCurrentLocationSelector,
} from '@core/store/slices/programs';
import { useCurrentReportSelector, useReportsSelector } from '@core/store/slices/reports';
import { setSelectedSample, useSamplesSelector } from '@core/store/slices/samples';
import { isZoomInBySidebar, isZoomOutBySidebar } from '@core/store/utils/sidebar/zoom';
import { areAllValuesValid, detectIsSensorDevice, isOneValueValid } from '@core/utils';
import { findClosestByLongLat, sanitizePolygonCoordinates } from '@core/utils/geospatial';
import { getActiveViewerByViewerKey } from '@modules/Sidebar/utils';
import { SiteCreationPerimeterProgressBar } from '@modules/Sidebar/views/CreateSite/components/ProgressBar';
import { Controls } from '@modules/Viewers/components/Controls';
import { EMapEvents } from './enums/events';
import {
  EAnomalyLayers,
  EFlightPathLayers,
  ELocationLayers,
  ESampleLayers,
  EZonesLayers,
} from './enums/layers';
import { EMapMoveType, EZoomInitiator } from './enums/mapMove';
import styles from './styles.scss';
import { addLocationCenter } from './utils/addLocationCenter';
import { addAnomalies } from './utils/anomalies/addAnomalies';
import { POPUP_ANOMALY_ID, createAnomalyPopup } from './utils/anomalies/createAnomalyPopup';
import { handleZoomToAnomaly } from './utils/anomalies/handleZoomToAnomaly';
import { initializeAnomalyMapboxConfig } from './utils/anomalies/initializeAnomalyMapboxConfig';
import { removeAnomalyPopup } from './utils/anomalies/removeAnomalyPopup';
import { customizeCompass } from './utils/customizeCompass';
import { addFlightPath } from './utils/flightPath/addFlightPath';
import {
  checkIntersectingPolygons,
  getIntersectionBetweenTwoPolygons,
  getMapboxBoundsBboxCoords,
  getBboxPolygon,
  getSiteBboxCoords,
} from './utils/geospatialUtils';
import { hideElements } from './utils/hideElements';
import { loadImages } from './utils/loadImages';
import { isZoomIn, isZoomOut } from './utils/mapbox/zoom';
import { addSamples } from './utils/samples/addSamples';
import { addSolarPanels } from './utils/solarPanels/addSolarPanels';
import { updateMap } from './utils/updateMap';
import { addZones } from './utils/zones/addZones';

const throttledUpdateMap = throttle(updateMap, 300);

interface IProps {
  type?: EViewerType;
  showControls?: boolean;
  minZoom?: number;
}

export const MapViewer = ({
  showControls = true,
  minZoom = 2,
  type = EViewerType.Viewer,
}: IProps) => {
  const { reload, previousZoom, isDynamicZoom } = useMapSelector();
  const { viewer, sidebarViewer } = useViewerSelector();

  const { sites, loading: isSitesLoading } = useSitesSelector();
  const { sidebar, sidebarPrevious } = useSidebarSelector();
  const controls = useControlsSelector();
  const currentSite = useCurrentSiteSelector();
  const selectedAnomaly = useCurrentAnomalySelector();
  const { anomaliesType } = useAnomaliesSelector();
  const program = useCurrentProgramSelector();
  const {
    anomalySamples,
    selectedSample,
    currentReportSamples,
    loading: isReportSamplesLoading,
  } = useSamplesSelector();

  const { currentInspectionId } = useInspectionsSelector();
  const visibleInspections = useVisibleInspectionsSelector();
  const { reports } = useReportsSelector();
  const currentReport = useCurrentReportSelector();
  const programsForCurrentLocation = useProgramsForCurrentLocationSelector();
  const currentProgram = useCurrentProgramSelector();
  const dispatch = useDispatchTyped();

  const { lat: createSiteLat, lng: createSiteLng } = useCreateSiteSelectedLocation();
  const {
    programs: generatedPrograms,
    currentOverviewIndex,
    currentPlanIndex,
    programPreview,
  } = useCreateSiteZones();
  const currentGeneratedProgram = useCurrentCreateSiteProgramSelector();
  const { current: createSiteCurrentStep } = useCreateSiteSteps();
  const currentPerimeter = useCurrentPerimeterSelector();
  const currentNoFlyZone = useCurrentNoFlyZoneSelector();
  const currentObstacle = useCurrentObstacleSelector();
  const currentObstacles = useSelectorTyped(
    (state) => state.createSite[ECreateSiteSections.Obstacles].data,
  ).filter((obstacle) => obstacle.id !== currentObstacle?.id);
  const noFlyZones = useSelectorTyped(
    (state) => state.createSite[ECreateSiteSections.NoFlyZones].data,
  ).filter((noFlyZone) => noFlyZone.id !== currentNoFlyZone?.id);
  const newSiteLocationCoordinates = useNewSiteLocationCoordinates();

  const [isMoving, setIsMoving] = useState(false);
  const [currentStyle, setCurrentStyle] = useState<string | null>(REGULAR_STYLE_URL);
  const [isStyleLoaded, setIsStyleLoaded] = useState(false);

  const {
    map,
    mapContainer,
    handleInitializing,
    handleZoomIn,
    handleZoomOut,
    handleZoomCenter,
    handleZoomTo,
  } = useMapbox({ minZoom, compareMode: false });

  const mapZoomInitiatorRef = useRef<EZoomInitiator | null>(null);

  const isDynamicZoomRef = useRef(isDynamicZoom);
  isDynamicZoomRef.current = isDynamicZoom;

  const mapPreviousZoomRef = useRef(0);
  mapPreviousZoomRef.current = previousZoom;

  const sitesRef = useRef(sites);
  sitesRef.current = sites;

  const currentGeneratedProgramRef = useRef<IProgram | undefined | null>(null);
  currentGeneratedProgramRef.current = currentGeneratedProgram;

  const createSiteLngRef = useRef<number | null>(null);
  createSiteLngRef.current = createSiteLng;

  const createSiteLatRef = useRef<number | null>(null);
  createSiteLatRef.current = createSiteLat;

  const currentSiteRef = useRef<ISite | null | undefined>(null);
  currentSiteRef.current = currentSite;

  const currentViewerRef = useRef(viewer);
  currentViewerRef.current = viewer;

  const currentSidebarViewerRef = useRef(sidebarViewer);
  currentSidebarViewerRef.current = sidebarViewer;

  const currentSidebarRef = useRef<ESidebar | null>(null);
  currentSidebarRef.current = sidebar;

  const previousSidebarRef = useRef<ESidebar | null>(null);
  previousSidebarRef.current = sidebarPrevious;

  const currentInspectionIdRef = useRef<string | null>(null);
  currentInspectionIdRef.current = currentInspectionId ?? null;

  const currentReportRef = useRef<IReport | null>(null);
  currentReportRef.current = currentReport ?? null;

  const programsForCurrentLocationRef = useRef<IProgram[] | null>(null);
  programsForCurrentLocationRef.current = programsForCurrentLocation;

  const createSiteCurrentStepRef = useRef<number | null>(null);
  createSiteCurrentStepRef.current = createSiteCurrentStep;

  const obstaclesRef = useRef<IObstacle[]>([]);
  obstaclesRef.current = currentObstacles;

  const currentObstacleRef = useRef<IObstacle | null>(null);
  currentObstacleRef.current = currentObstacle ?? null;

  const noFlyZonesRef = useRef<INoFlyZone[]>([]);
  noFlyZonesRef.current = noFlyZones ?? [];

  const currentNoFlyZoneRef = useRef<INoFlyZone | null>(null);
  currentNoFlyZoneRef.current = currentNoFlyZone ?? null;

  const currentPerimeterRef = useRef<FeatureCollection | null>(null);
  currentPerimeterRef.current = currentPerimeter ?? null;

  const generatedProgramsRef = useRef<IProgram[]>([]);
  generatedProgramsRef.current = generatedPrograms;

  const anomalySamplesRef = useRef<ISample[]>([]);
  anomalySamplesRef.current = anomalySamples;

  const getVisibleSites = () => {
    if (!map.current) return;

    const bounds = map.current.getBounds();
    const boundsPolygon = getBboxPolygon(getMapboxBoundsBboxCoords(bounds));

    return sitesRef.current.filter((site) => {
      const sitePolygon = getBboxPolygon(getSiteBboxCoords(site));
      return getIntersectionBetweenTwoPolygons(boundsPolygon, sitePolygon);
    });
  };

  const getVisibleAnomalies = () => {
    if (!map.current) return;

    const bounds = map.current.getBounds();
    const boundsPolygon = getBboxPolygon(getMapboxBoundsBboxCoords(bounds));

    return currentReportRef.current?.anomalies?.filter((anomaly) => {
      const anomalyCoordinates = anomaly.features.length
        ? anomaly.features[0].geometry.coordinates?.[0]
        : null;

      if (!anomalyCoordinates) {
        return false;
      }

      const sanitizedCoordinates = sanitizePolygonCoordinates(anomalyCoordinates);
      const anomalyPolygon = getBboxPolygon(sanitizedCoordinates);
      return getIntersectionBetweenTwoPolygons(boundsPolygon, anomalyPolygon);
    });
  };

  const updateSidebar = (event: EMapEvents.Drag | EMapEvents.Zoom) => {
    const visibleSites = getVisibleSites();
    if (!visibleSites) return;
    dispatch(
      updateSitesSidebarAndCurrentInspectionId({
        visibleSites,
        event,
      }),
    );
  };

  const zoomOutFromMultipleSites = (
    sites: ISite[],
    currentZoom: number,
    currentSidebar: ESidebar,
  ) => {
    const visibleSitesPolygons = sites.map((site) => getBboxPolygon(getSiteBboxCoords(site)));
    const hasIntersectingPolygons = checkIntersectingPolygons(visibleSitesPolygons);
    const { minZoom } = mapZooms[currentSidebar] as ZoomRange;
    const isZoomingOut = isZoomOut(currentZoom, mapPreviousZoomRef.current);

    if (
      (sites?.length > 1 && !hasIntersectingPolygons) ||
      (isZoomingOut && currentZoom < minZoom)
    ) {
      if (currentSidebar === ESidebar.Zone) {
        dispatch(setSidebar(ESidebar.Site));
      } else {
        dispatch(setSidebar(ESidebar.Sites));
      }
    }
  };

  // NOTE: use case (map styles): change map style
  useEffect(() => {
    if (!map.current) return;

    const desiredStyle = [ESidebar.CreateSite].includes(sidebar)
      ? SATELLITE_STYLE_URL
      : REGULAR_STYLE_URL;
    if (currentStyle !== desiredStyle) {
      map.current.setStyle(desiredStyle);
      setCurrentStyle(desiredStyle);
    }
  }, [map.current, sidebar, currentStyle]);

  const handleMoveToCreateSite = (site: ISite) => {
    const isProcessingSite =
      site.location_status &&
      [ELocationStatus.WaitingForApproval, ELocationStatus.UnderCreation].includes(
        site.location_status,
      );

    if (isProcessingSite) {
      dispatch(continueCreation(site.loc_id));
    }
    return isProcessingSite;
  };

  const togglePreview = () => {
    if (!map.current) return;
    if (currentSidebarRef.current === ESidebar.CreateSite) return;

    const visibleSites = getVisibleSites();
    if (!visibleSites || !visibleSites.length) return;

    if (currentSidebarRef.current === ESidebar.Sites) {
      if (visibleSites?.length === 1) {
        const currentZoom = map.current?.getZoom();
        const [site] = visibleSites;

        // 1. Restrict zoom out if there is only one site on the map
        const isSingleSite = sites.length === 1;
        if (isSingleSite) {
          map.current?.setMinZoom(MIN_ZOOM_FOR_ONE_SITE);
          const isProcessingSite = handleMoveToCreateSite(site);
          if (isProcessingSite) return;

          dispatch(addSelectedSiteInfo({ siteId: site.loc_id }));
          dispatch(setSidebar(ESidebar.Site));
          return;
        }

        // 2. Restrict zoom in to the threshold level if there is one site on the map
        const isOneSiteFromSeverals = sites.length > 1;
        if (isOneSiteFromSeverals && currentZoom > THRESHOLD_ZOOM_IN_FOR_ONE_SITE_FROM_SEVERALS) {
          const isProcessingSite = handleMoveToCreateSite(site);
          if (isProcessingSite) return;

          if (previousSidebarRef.current !== ESidebar.CreateSite) {
            dispatch(addSelectedSiteInfo({ siteId: site.loc_id }));
            dispatch(setSidebar(ESidebar.Site));
            return;
          }
        }
      }
    }

    if (
      ![ESidebar.Sites, ESidebar.Zone, ESidebar.Anomaly, null].includes(
        currentSidebarRef.current,
      ) &&
      currentSidebarRef.current
    ) {
      const currentZoom = map.current?.getZoom();
      zoomOutFromMultipleSites(visibleSites, currentZoom, currentSidebarRef.current);
    }
  };

  const handleDrag = () => updateSidebar(EMapEvents.Drag);
  const handleDragEnd = () => {
    // NOTE: update boundaries for created site
    if (currentSidebarRef.current === ESidebar.CreateSite) {
      const bounds = map.current?.getBounds();

      if (bounds) {
        const bbox = getMapboxBoundsBboxCoords(bounds);
        const [topLeftLng, topLeftLat] = bbox[0]; // northWest
        const [bottomRightLng, bottomRightLat] = bbox[2]; // southEast

        dispatch(
          setPerimeterGpsBoundaries({
            top_left_lat: topLeftLat,
            top_left_lng: topLeftLng,
            bottom_right_lat: bottomRightLat,
            bottom_right_lng: bottomRightLng,
          }),
        );
      }
    }

    togglePreview();
  };
  const handleZoom = (event: MapEventType['zoom']) => {
    updateSidebar(EMapEvents.Zoom);

    const currentZoom = event.target.getZoom();
    const currentMinZoom = event.target.getMinZoom();

    if (isZoomOut(currentZoom, mapPreviousZoomRef.current)) {
      // NOTE: use case (anomaly "minZoom" level): restrict "zoom out" on Anomaly level to another one
      if (currentSidebarRef.current === ESidebar.Anomaly) {
        const areAllAnomaliesVisible =
          getVisibleAnomalies()?.length === currentReportRef.current?.anomalies?.length;

        if (areAllAnomaliesVisible && currentMinZoom === minZoom) {
          const minAnomalyZoom = currentZoom;
          event.target.setMinZoom(minAnomalyZoom);
        }
      }
    }
  };

  const handleCenterZoom = () => {
    handleZoomCenter(sitesRef.current.map((site) => [site.long, site.lat]));
  };

  const handleSetZoomLevelOnSelectedAnomalyInSidebar = useCallback(() => {
    const frustumCoordinates = selectedSample?.geodetic_camera?.frustum?.map(({ lat, lon }) => [
      lon,
      lat,
    ]);
    const samplesCoordinates = anomalySamples.map((sample) => [
      sample.geodetic_camera?.lon,
      sample.geodetic_camera?.lat,
    ]) as number[][];

    if (frustumCoordinates?.length) {
      const bboxPolygon = getBboxPolygon([...frustumCoordinates, ...samplesCoordinates]);

      const boundsLike = bboxPolygon.bbox as LngLatBoundsLike;
      const { maxZoom } = mapZooms[ESidebar.Anomaly] as ZoomRange;
      map.current?.fitBounds(boundsLike, {
        padding: 10,
        maxZoom,
      });
    }
  }, [selectedSample, anomalySamples]);

  // NOTE: use case (zoom level) - selected anomaly in the sidebar map
  useEffect(() => {
    const activeMapViewer = getActiveViewerByViewerKey(
      currentViewerRef.current,
      currentSidebarViewerRef.current,
      EViewer.Map,
    );

    const isSidebarMapWithAnomalyLevel = areAllValuesValid(
      activeMapViewer === EMapViewerActive.Sidebar,
      currentSidebarRef.current === ESidebar.Anomaly,
    );

    if (isSidebarMapWithAnomalyLevel) {
      handleSetZoomLevelOnSelectedAnomalyInSidebar();
    }
  }, [
    currentSidebarRef.current,
    currentSidebarViewerRef.current,
    handleSetZoomLevelOnSelectedAnomalyInSidebar,
  ]);

  const handleClickZoomIn = useCallback(() => {
    mapZoomInitiatorRef.current = EZoomInitiator.Control;
    handleZoomIn();
  }, [handleZoomIn]);

  const handleClickZoomOut = useCallback(() => {
    mapZoomInitiatorRef.current = EZoomInitiator.Control;
    handleZoomOut();
  }, [handleZoomOut]);

  const handleDynamicZoom = () => {
    mapZoomInitiatorRef.current = EZoomInitiator.Auto;

    switch (currentSidebarRef.current) {
      case null:
      case ESidebar.Sites:
        handleCenterZoom();
        break;
      case ESidebar.Zone:
        if (currentProgram?.long && currentProgram?.lat) {
          return handleZoomTo(currentProgram.long, currentProgram.lat, dynamicZooms[ESidebar.Zone]);
        }
        break;
      case ESidebar.Anomaly:
        if (selectedAnomaly) {
          return handleZoomToAnomaly(map.current, selectedAnomaly, {
            zoom: dynamicZooms[ESidebar.Anomaly],
          });
        }
        break;
      case ESidebar.CreateSite:
        if (currentGeneratedProgramRef.current) {
          const perimeterCenterFeature = turf.centroid(
            currentGeneratedProgramRef.current?.display_perimeter,
          );
          const [long, lat] = perimeterCenterFeature.geometry.coordinates;
          return handleZoomTo(long, lat, dynamicZooms[ESidebar.Site]);
        }
        if (createSiteLngRef.current && createSiteLatRef.current) {
          return handleZoomTo(createSiteLngRef.current, createSiteLatRef.current, CREATE_SITE_ZOOM);
        }
        break;
      default:
        // NOTE: "Site", "Edit site"
        if (currentSite?.lat && currentSite?.long) {
          return handleZoomTo(currentSite.long, currentSite.lat, dynamicZooms[sidebar] ?? NaN);
        }
        break;
    }
  };

  const handleZoomEnd = (event: MapEventType['zoomend']) => {
    if (!map.current) return;

    const activeMapViewer = getActiveViewerByViewerKey(
      currentViewerRef.current,
      currentSidebarViewerRef.current,
      EViewer.Map,
    );

    const shouldPreventZooming = isOneValueValid(
      activeMapViewer === EMapViewerActive.Sidebar, // NOTE: it's the same as EViewerType.InspectionView. There is a problem with closures.
      type === EViewerType.InspectionView,
      currentSidebarRef.current === ESidebar.Anomaly,
    );

    const currentZoom = map.current.getZoom();

    if (currentSidebarRef.current === ESidebar.CreateSite) {
      dispatch(setPreviousZoom(currentZoom));
      return;
    }
    if (shouldPreventZooming) return;

    const visibleSites = getVisibleSites();
    if (!visibleSites) return;

    const { maxZoom: siteMaxZoom } = mapZooms[ESidebar.Site] as ZoomRange;
    const { minZoom: zoneMinZoom } = mapZooms[ESidebar.Zone] as ZoomRange;

    const zoomInConditions = [isZoomIn(currentZoom, mapPreviousZoomRef.current)];
    const zoomOutConditions = [isZoomOut(currentZoom, mapPreviousZoomRef.current)];
    const zoomInBySidebar = isZoomInBySidebar(
      currentSidebarRef.current,
      previousSidebarRef.current,
    );
    const zoomOutBySidebar = isZoomOutBySidebar(
      currentSidebarRef.current,
      previousSidebarRef.current,
    );

    // NOTE: check if the action was done by the user and not the system ("flyTo" action).
    const isUserMovement = 'originalEvent' in event;

    if (isUserMovement) {
      mapZoomInitiatorRef.current = EZoomInitiator.Manual;
    } else {
      if (!mapZoomInitiatorRef.current) {
        mapZoomInitiatorRef.current = EZoomInitiator.Auto;
      }
    }

    switch (mapZoomInitiatorRef.current) {
      case EZoomInitiator.Control:
      case EZoomInitiator.Manual: {
        break;
      }
      case EZoomInitiator.Auto: {
        // NOTE: use case (one site): add additional checking for one site
        const isSingleSite = sitesRef.current.length === 1;

        if (isSingleSite) {
          if (
            areAllValuesValid(
              currentSidebarRef.current !== ESidebar.Sites,
              currentSidebarRef.current !== null,
            )
          ) {
            zoomInConditions.push(zoomInBySidebar);
            zoomOutConditions.push(zoomOutBySidebar);
          }
        } else {
          zoomInConditions.push(zoomInBySidebar);
          zoomOutConditions.push(zoomOutBySidebar);
        }
        break;
      }
    }

    if (areAllValuesValid(...zoomInConditions)) {
      togglePreview();

      switch (currentSidebarRef.current) {
        case ESidebar.Site: {
          // 1. Zoom in from "Site" to "Zone"
          if (currentZoom > zoneMinZoom && programsForCurrentLocationRef.current) {
            const { lng, lat } = map.current.getCenter();
            const closestProgram: IProgram | null = findClosestByLongLat<IProgram>(
              programsForCurrentLocationRef.current,
              lng,
              lat,
            );

            if (closestProgram?.long && closestProgram.lat) {
              dispatch(setCurrentProgramId(closestProgram.program_id));
              dispatch(setCurrentInspectionIdByProgramId({ programId: closestProgram.program_id }));
              dispatch(setSidebar(ESidebar.Zone));
            }
          }
          break;
        }
      }
    } else if (areAllValuesValid(...zoomOutConditions)) {
      const currentZoom = map.current.getZoom();
      switch (currentSidebarRef.current) {
        case ESidebar.Site: {
          if (visibleSites.length > 1)
            zoomOutFromMultipleSites(visibleSites, currentZoom, ESidebar.Site);
          break;
        }
        case ESidebar.Zone: {
          // 1. Zoom out from "Zone" to "Sites
          if (visibleSites.length > 1) {
            zoomOutFromMultipleSites(visibleSites, currentZoom, ESidebar.Zone);
            // 2. Zoom out from "Zone" to "Site"
          } else if (currentZoom <= siteMaxZoom) {
            const currentSiteCopy = currentSiteRef.current;
            setTimeout(() => {
              if (currentSiteCopy) {
                dispatch(setCurrentSiteIdAndUpdatePrograms(currentSiteCopy.loc_id));
              }
            }, 100);
            dispatch(setSidebar(ESidebar.Site));
          }
          break;
        }
      }
    }

    dispatch(setPreviousZoom(currentZoom));
  };

  const handleMoveEnd = () => {
    setIsMoving(false);
    if (!map.current) return;
    if (currentSidebarRef.current === ESidebar.CreateSite) {
      const bounds = map.current.getBounds();
      const topLeft = bounds.getNorthWest(); // top left point
      const bottomRight = bounds.getSouthEast(); // bottom right point

      dispatch(
        setPerimeterGpsBoundaries({
          top_left_lat: topLeft.lat,
          top_left_lng: topLeft.lng,
          bottom_right_lat: bottomRight.lat,
          bottom_right_lng: bottomRight.lng,
        }),
      );
    }
  };

  const handleMove = useCallback(
    (id: string, type: EMapMoveType = EMapMoveType.Site) => {
      if (isMoving && type !== EMapMoveType.Anomaly) return;

      setIsMoving(true);
      const types = {
        [EMapMoveType.CreateSite]: {
          sidebar: null,
          actions: [(siteId: string) => continueCreation(siteId)],
        },
        [EMapMoveType.Site]: {
          sidebar: ESidebar.Site,
          actions: [(siteId: string) => addSelectedSiteInfo({ siteId })],
        },
        [EMapMoveType.Zone]: {
          sidebar: ESidebar.Zone,
          actions: [
            (programId: string) => setCurrentProgramId(programId),
            (programId: string) => setCurrentInspectionIdByProgramId({ programId }),
          ],
        },
        [EMapMoveType.Anomaly]: {
          sidebar: ESidebar.Anomaly,
          actions: [(anomalyId: string) => setCurrentAnomalyId(Number(anomalyId))],
        },
      };

      types[type].actions.forEach((handler) => dispatch(handler(id)));
      const selectedSidebar = types[type].sidebar;

      if (selectedSidebar) {
        dispatch(setSidebar(selectedSidebar));
      }
    },
    [dispatch, isMoving],
  );

  const handleMapboxError = useCallback((e) => console.error({ mapboxError: e }), []);
  const handleStyleLoad = useCallback(() => setIsStyleLoaded(true), []);

  const handleSiteClick = useCallback(
    debounce((event: MapClickEvent) => {
      const shouldPreventSiteClick = currentSidebarRef.current !== ESidebar.Sites;
      if (shouldPreventSiteClick) return;

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: [ELocationLayers.UnclusteredPoint],
      });

      if (!features.length) return;

      const locationProperties = features[0].properties;
      const { loc_id, type } = locationProperties || { loc_id: null, type: null };

      const isCreateSiteType = [
        ELocationStatus.UnderCreation,
        ELocationStatus.WaitingForApproval,
      ].includes(type);

      const siteType = isCreateSiteType ? EMapMoveType.CreateSite : EMapMoveType.Site;

      if (loc_id) handleMove(loc_id, siteType);
    }),
    [handleMove],
  );

  const handleZoneClick = useCallback(
    debounce((event: MapClickEvent) => {
      const shouldPreventZoneClick = currentSidebarRef.current !== ESidebar.Site;
      if (shouldPreventZoneClick) return;

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: [EZonesLayers.Polygon, EZonesLayers.PolygonBorder],
      });

      if (!features.length) return;

      const zoneProperties = features[0]?.properties;

      if (zoneProperties?.id) {
        handleMove(String(zoneProperties.id), EMapMoveType.Zone);
      }
    }),
    [handleMove],
  );

  const handleAnomalyClick = useCallback(
    debounce((event: MapClickEvent) => {
      const shouldPreventAnomalyClick =
        !currentSidebarRef.current ||
        ![ESidebar.Zone, ESidebar.Anomaly].includes(currentSidebarRef.current) ||
        detectIsSensorDevice();

      if (shouldPreventAnomalyClick) return;

      const layersIds: string[] = [EAnomalyLayers.Anomalies];

      if (event.target.getLayer(ESampleLayers.Samples)) {
        layersIds.push(ESampleLayers.Samples);
      }

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: layersIds,
      });

      const clickedFeatures: Record<string, mapboxgl.MapboxGeoJSONFeature | null> = {
        [EAnomalyLayers.Anomalies]: null,
        [ESampleLayers.Samples]: null,
      };

      features?.forEach((feature) => {
        const layerId = feature.layer.id;

        if (clickedFeatures.hasOwnProperty(layerId)) {
          clickedFeatures[layerId] = feature;
        }
      });

      if (clickedFeatures[ESampleLayers.Samples]) return;
      if (clickedFeatures[EAnomalyLayers.Anomalies]) {
        const anomalyId = clickedFeatures[EAnomalyLayers.Anomalies].id;
        // "InspectionView" mode in Zone sidebar. Move to the default Anomaly sidebar
        if (currentSidebarRef.current === ESidebar.Zone) {
          dispatch(setViewer(EViewer.Map));
          dispatch(setSidebarViewer(EViewer.Image));
        }
        handleMove(String(anomalyId), EMapMoveType.Anomaly);
      }
    }),
    [dispatch, handleMove],
  );

  const handleAnomalyMouseMove = useCallback(
    debounce((event: MapMouseMoveEvent) => {
      const layersIds: string[] = [EAnomalyLayers.Anomalies];

      if (event.target.getLayer(ESampleLayers.Samples)) {
        layersIds.push(ESampleLayers.Samples);
      }

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: layersIds,
      });

      const mouseEnteredFeatures: Record<string, mapboxgl.MapboxGeoJSONFeature | null> = {
        [EAnomalyLayers.Anomalies]: null,
        [ESampleLayers.Samples]: null,
      };

      features?.forEach((feature) => {
        const layerId = feature.layer.id;

        if (mouseEnteredFeatures.hasOwnProperty(layerId)) {
          mouseEnteredFeatures[layerId] = feature;
        }
      });

      if (mouseEnteredFeatures[ESampleLayers.Samples]) return;
      if (mouseEnteredFeatures[EAnomalyLayers.Anomalies]) {
        const anomalyFeature = mouseEnteredFeatures[EAnomalyLayers.Anomalies];
        const anomalyFeatureCoordinates = anomalyFeature.properties?.coordinates;

        if (anomalyFeature && anomalyFeatureCoordinates) {
          const deserializedCoordinates: mapboxgl.LngLat =
            DataProcessing.deserialize(anomalyFeatureCoordinates);

          // use case (two different anomalies located without gap)
          const popup = document.getElementById(POPUP_ANOMALY_ID);
          const anomalyId = Number(popup?.dataset.id);
          if (anomalyId && anomalyFeature.id !== anomalyId) {
            removeAnomalyPopup({ map: event.target });
            createAnomalyPopup({
              map: event.target,
              anomalyFeature,
              coordinates: deserializedCoordinates,
            });
          }
        }
      }
    }),
    [],
  );

  const handleAnomalyMouseEnter = useCallback(
    debounce((event: MapMouseEnterEvent) => {
      const layersIds: string[] = [EAnomalyLayers.Anomalies];

      if (event.target.getLayer(ESampleLayers.Samples)) {
        layersIds.push(ESampleLayers.Samples);
      }

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: layersIds,
      });

      const mouseEnteredFeatures: Record<string, mapboxgl.MapboxGeoJSONFeature | null> = {
        [EAnomalyLayers.Anomalies]: null,
        [ESampleLayers.Samples]: null,
      };

      features?.forEach((feature) => {
        const layerId = feature.layer.id;

        if (mouseEnteredFeatures.hasOwnProperty(layerId)) {
          mouseEnteredFeatures[layerId] = feature;
        }
      });

      if (mouseEnteredFeatures[ESampleLayers.Samples]) return;
      if (mouseEnteredFeatures[EAnomalyLayers.Anomalies]) {
        const anomalyFeature = mouseEnteredFeatures[EAnomalyLayers.Anomalies];
        const anomalyFeatureCoordinates = anomalyFeature.properties?.coordinates;

        if (anomalyFeature && anomalyFeatureCoordinates) {
          const deserializedCoordinates: mapboxgl.LngLat =
            DataProcessing.deserialize(anomalyFeatureCoordinates);

          const popup = document.getElementById(POPUP_ANOMALY_ID);

          if (!popup) {
            createAnomalyPopup({
              map: event.target,
              anomalyFeature,
              coordinates: deserializedCoordinates,
            });
          }
        }
      }
    }),
    [],
  );

  const handleAnomalyMouseLeave = useCallback(
    debounce((event: MapMouseLeaveEvent) => {
      const anomalyFeatures = event.target.queryRenderedFeatures(event.point, {
        layers: [EAnomalyLayers.Anomalies],
      });
      if (!anomalyFeatures.length) {
        removeAnomalyPopup({ map: event.target });
      }
    }),
    [],
  );

  const handleSampleClick = useCallback(
    debounce((event: MapClickEvent) => {
      const shouldPreventSampleClick =
        !currentSidebarRef.current ||
        ![ESidebar.Zone, ESidebar.Anomaly].includes(currentSidebarRef.current);

      if (shouldPreventSampleClick) return;

      const features = event.target.queryRenderedFeatures(event.point, {
        layers: [ESampleLayers.Samples],
      });
      const sampleId = features[0].id;
      if (sampleId) {
        const anomalySample = anomalySamplesRef.current.find((sample) => sample.id === sampleId);

        if (anomalySample) {
          dispatch(setSelectedSample(anomalySample));
        }
      }
    }),
    [dispatch],
  );

  const handleSampleMouseEnter = useCallback(
    debounce((event: MapMouseEnterEvent) => {
      event.target.getCanvas().style.cursor = 'pointer';
      removeAnomalyPopup({ map: event.target });
    }),
    [],
  );

  const handleSampleMouseLeave = useCallback(
    debounce((event: MapMouseLeaveEvent) => {
      event.target.getCanvas().style.cursor = '';
    }),
    [],
  );
  const handleChangePerimeterGeoJson = (data: FeatureCollection) => {
    dispatch(updatePerimeter(data));
  };

  const handleChangeNoFlyZoneGeoJson = (data: FeatureCollection) => {
    const firstFeature: Feature<any> | undefined = data.features.at(0);
    if (firstFeature) dispatch(updateCurrentNoFlyZonePerimeter(firstFeature));
  };

  const handleChangeObstacleGeoJson = (data: FeatureCollection) => {
    const firstFeature: Feature<any> | undefined = data.features.at(0);
    if (firstFeature) dispatch(updateCurrentObstaclePerimeter(firstFeature));
  };

  const handleUpdateMap = () => {
    // NOTE: Disable this map updates for compare mode
    if (!map.current || controls.isCompareMode) return;
    if (!currentSidebarRef.current || currentSidebarRef.current !== sidebar) return;
    if (!isStyleLoaded) return;

    const colorSolarPanels = controls.hasSolarPanels ? '--outflier-color2' : '--outflier-color4';
    const drawBatteries = sidebar === ESidebar.Zone && controls.hasFlightPath;

    throttledUpdateMap({
      map: map.current,
      sites,
      program: currentProgram,
      programs: programsForCurrentLocation,
      showSolarPanels: controls.hasSolarPanels,
      currentInspectionId: currentInspectionIdRef.current,
      report: currentReport ?? currentReportRef.current ?? null,
      reports: reports,
      sidebar: currentSidebarRef.current ?? sidebar,
      sidebarPrevious,
      showFlightPath: controls.hasFlightPath,
      colorSolarPanels,
      selectedAnomaly,
      onSiteClick: handleSiteClick,
      onZoneClick: handleZoneClick,
      onAnomalyClick: handleAnomalyClick,
      onAnomalyMouseEnter: handleAnomalyMouseEnter,
      onAnomalyMouseLeave: handleAnomalyMouseLeave,
      onAnomalyMouseMove: handleAnomalyMouseMove,
      flightPath: currentReportSamples?.length ? currentReportSamples : undefined,
      flightPlan: program?.flightPlan ?? null,
      drawBatteries,
      createSiteCurrentStep: createSiteCurrentStepRef.current,
      noFlyZones: noFlyZonesRef.current,
      obstacles: obstaclesRef.current,
      perimeter: currentPerimeterRef.current,
      onEditPerimeter: handleChangePerimeterGeoJson,
      noFlyZone: currentNoFlyZoneRef.current,
      onEditNoFlyZone: handleChangeNoFlyZoneGeoJson,
      obstacle: currentObstacleRef.current,
      onEditObstacle: handleChangeObstacleGeoJson,
      dispatch,
      generatedPrograms,
      currentGeneratedProgram,
      currentPlanIndex,
      currentOverviewIndex,
      programPreview,
    });
  };

  const handleDynamicZoomStart = () => {
    if (isDynamicZoomRef.current) {
      handleDynamicZoom();
    }
    dispatch(setIsDynamicZoom(true));
  };

  const handleLoad = () => {
    if (map.current) {
      handleUpdateMap();
      handleDynamicZoomStart();
    }
  };

  // TODO: check "createSiteCurrentStep" field in useEffect dependencies
  useEffect(() => {
    if (
      INITIAL_CREATE_SITE_COORDINATES.lat !== newSiteLocationCoordinates.lat ||
      INITIAL_CREATE_SITE_COORDINATES.lng !== newSiteLocationCoordinates.lng
    ) {
      if (sidebar !== ESidebar.CreateSite || !map.current) return;

      // NOTE: there were problems with page reloading
      if (map.current.isStyleLoaded()) {
        addLocationCenter({
          map: map?.current,
          sidebar,
          coordinates: newSiteLocationCoordinates,
          createSiteStep: createSiteCurrentStep,
        });
      } else {
        map.current.once('style.load', () => {
          addLocationCenter({
            map: map?.current,
            sidebar,
            coordinates: newSiteLocationCoordinates,
            createSiteStep: createSiteCurrentStep,
          });
        });
      }
    }
  }, [newSiteLocationCoordinates, sidebar, map?.current, createSiteCurrentStep]);

  const updateMapMinZoom = useCallback(() => {
    if (!map.current) return;

    if (sidebar !== ESidebar.Anomaly) {
      const currentMinZoom = map.current.getMinZoom();

      // NOTE: use case (one site): add additional checking for one site
      const isSingleSite = sitesRef.current.length === 1;
      if (isSingleSite) {
        map.current.setMinZoom(MIN_ZOOM_FOR_ONE_SITE);
      } else {
        if (currentMinZoom !== minZoom) {
          map.current.setMinZoom(minZoom);
        }
      }
    }
  }, [sidebar, minZoom]);

  // NOTE: use case (min zoom level): update "minZoom" if the sidebar is not an anomaly.
  useEffect(() => {
    updateMapMinZoom();
  }, [updateMapMinZoom]);

  // NOTE: use case(site creation): zoom in with default zoom to create site.
  useEffect(() => {
    if (sidebar !== ESidebar.CreateSite || !createSiteLng || !createSiteLat || !map.current) return;
    handleZoomTo(createSiteLng, createSiteLat, CREATE_SITE_ZOOM);
  }, [map.current, createSiteLat, createSiteLng, sidebar]);

  useEffect(() => {
    if (!reload) return;
    handleUpdateMap();

    handleDynamicZoomStart();

    dispatch(reloadMap(false));
  });

  useEffect(() => {
    if (sidebar === ESidebar.CreateSite) {
      handleUpdateMap();
      handleDynamicZoomStart();
    }
  }, [
    sidebar,
    currentNoFlyZone,
    currentObstacle,
    generatedPrograms,
    currentGeneratedProgram,
    currentOverviewIndex,
    currentPlanIndex,
    programPreview,
  ]);

  // NOTE: use case (style is not loading): protect rare initial error.
  useEffect(() => {
    if (isStyleLoaded) {
      dispatch(reloadMap(true));
    }
  }, [isStyleLoaded]);

  useEffect(() => {
    if (isSitesLoading || !sites.length || map.current) return;

    const onLoaded = () => {
      if (!map.current) return;

      if (type === EViewerType.Viewer) {
        map.current.addControl(new mapboxgl.ScaleControl({ unit: 'metric' }), 'bottom-right');
        map.current.addControl(
          new mapboxgl.NavigationControl({ showZoom: false, showCompass: showControls }),
          'bottom-right',
        );
      }

      hideElements();
      customizeCompass();
      loadImages(map.current);

      map.current.on('drag', handleDrag);
      map.current.on('dragend', handleDragEnd);
      map.current.on('zoom', handleZoom);
      map.current.on('load', handleLoad);
      map.current.on('zoomend', handleZoomEnd);
      map.current.on('moveend', handleMoveEnd);
      map.current.on('style.load', handleStyleLoad);
      map.current.on('error', handleMapboxError);
    };

    const initializeParams: [() => void, boolean?, IMapConfig?] = [onLoaded];
    // NOTE: set config for selected anomaly and center at the beginning (InspectionsView)
    if (selectedAnomaly?.features?.[0]) {
      const anomalyMapConfig = initializeAnomalyMapboxConfig(selectedAnomaly.features[0], minZoom);
      initializeParams.push(false, anomalyMapConfig);
    }

    handleInitializing(...initializeParams);
  });

  // NOTE: clean up resources when the map is not available
  useEffect(() => {
    return () => {
      map.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (controls.isCompareMode) return;
    if (!map.current) return;
    if (!isStyleLoaded) return;
    if (!currentSidebarRef.current || currentSidebarRef.current !== sidebar) return;

    addAnomalies({
      sidebar: sidebar,
      anomaliesType,
      selectedAnomaly,
      map: map.current,
      onClick: handleAnomalyClick,
      onMouseEnter: handleAnomalyMouseEnter,
      onMouseLeave: handleAnomalyMouseLeave,
      onMouseMove: handleAnomalyMouseMove,
      report: currentReport ?? null,
      isCompareMode: false,
    });
  }, [
    anomaliesType,
    selectedAnomaly,
    sidebar,
    currentReport,
    controls.isCompareMode,
    isStyleLoaded,
    handleAnomalyClick,
    handleAnomalyMouseEnter,
    handleAnomalyMouseLeave,
    handleAnomalyMouseMove,
  ]);

  useEffect(() => {
    if (controls.isCompareMode) return;
    if (!map.current) return;
    if (!isStyleLoaded) return;
    if (!currentSidebarRef.current || currentSidebarRef.current !== sidebar) return;

    addSolarPanels({
      color: controls.hasSolarPanels ? '--outflier-color2' : '--soft-grey',
      sidebar,
      sidebarPrevious,
      map: map.current,
      report: currentReport ?? null,
      show: controls.hasSolarPanels,
      withControl: true,
      isCompareMode: false,
    });
  }, [
    sidebar,
    sidebarPrevious,
    currentReport,
    controls.hasSolarPanels,
    controls.isCompareMode,
    isStyleLoaded,
  ]);

  useEffect(() => {
    if (!map.current) return;
    if (!isStyleLoaded) return;
    if (!currentSidebarRef.current || currentSidebarRef.current !== sidebar) return;

    addZones({
      map: map.current,
      programs: programsForCurrentLocation,
      sidebar,
      onClick: handleZoneClick,
    });
  }, [sidebar, programsForCurrentLocation, handleZoneClick, isStyleLoaded]);

  useEffect(() => {
    if (!map.current) return;

    const anomalyLayer = map.current.getLayer(EAnomalyLayers.Anomalies);
    const flightPathLayer = map.current.getLayer(EFlightPathLayers.FlightPath);

    if (!anomalyLayer && !flightPathLayer) return;

    switch (sidebar) {
      case ESidebar.Zone:
        addFlightPath({
          map: map.current,
          show: controls.hasFlightPath && !!currentReportSamples?.length,
          flightPath: currentReportSamples,
          drawBatteries: controls.hasFlightPath,
          flightPlan: program?.flightPlan ?? null,
        });
        break;
      case ESidebar.Anomaly:
        addFlightPath({
          map: map.current,
          show: controls.hasFlightPath && !!currentReportSamples?.length,
          flightPath: currentReportSamples,
          flightPlan: program?.flightPlan ?? null,
          drawBatteries: false,
        });
        break;
    }
  }, [
    sidebar,
    controls.hasFlightPath,
    program?.flightPlan,
    currentReportSamples,
    map.current?.getLayer(EAnomalyLayers.Anomalies),
  ]);

  useEffect(() => {
    if (!map.current) return;

    const flightPathLayer = map.current?.getLayer(EFlightPathLayers.FlightPath);
    const samplesLayer = map.current?.getLayer(ESampleLayers.Samples);

    const shouldPreventAddSamples = isOneValueValid(
      controls.isCompareMode,
      !flightPathLayer && !samplesLayer,
      !currentSidebarRef.current,
      currentSidebarRef.current !== sidebar,
    );

    if (shouldPreventAddSamples) return;

    if (sidebar === ESidebar.Anomaly) {
      addSamples({
        samples: anomalySamples,
        selectedSample,
        map: map.current,
        show: controls.hasFlightPath,
        isFlightPathSamples: false,
        onClick: handleSampleClick,
        onMouseEnter: handleSampleMouseEnter,
        onMouseLeave: handleSampleMouseLeave,
      });
    }
  }, [
    selectedSample?.id,
    anomalySamples,
    controls.hasFlightPath,
    controls.isCompareMode,
    handleSampleClick,
    handleSampleMouseEnter,
    handleSampleMouseLeave,
    sidebar,
    map.current?.getLayer(EFlightPathLayers.FlightPath),
  ]);

  useEffect(() => {
    if (!map.current) return;
    const flightPathLayer = map.current.getLayer(EFlightPathLayers.FlightPath);
    const samplesLayer = map.current.getLayer(ESampleLayers.Samples);

    const shouldPreventAddSamplesWithFlightPath = isOneValueValid(
      controls.isCompareMode,
      !flightPathLayer && !samplesLayer,
      !currentSidebarRef.current,
      currentSidebarRef.current !== sidebar,
      !currentReportSamples && !isReportSamplesLoading,
    );

    if (shouldPreventAddSamplesWithFlightPath) return;

    if (!currentReportSamples && isReportSamplesLoading && controls.hasFlightPath) {
      dispatch(setPreloader(true));
    }
    if (currentReportSamples && !isReportSamplesLoading && controls.hasFlightPath) {
      dispatch(setPreloader(false));
    }

    if (currentReportSamples?.length) {
      if (sidebar === ESidebar.Zone) {
        addSamples({
          samples: currentReportSamples,
          map: map.current,
          show: controls.hasFlightPath && !!currentReportSamples?.length,
          isFlightPathSamples: true,
          onClick: handleSampleClick,
          onMouseEnter: handleSampleMouseEnter,
          onMouseLeave: handleSampleMouseLeave,
        });
      }
    } else {
      addSamples({
        samples: [],
        map: map.current,
        show: false,
        isFlightPathSamples: false,
      });
    }
  }, [
    controls.hasFlightPath,
    controls.isCompareMode,
    sidebar,
    currentReportSamples,
    isReportSamplesLoading,
    handleSampleClick,
    handleSampleMouseEnter,
    handleSampleMouseLeave,
    map.current?.getLayer(EFlightPathLayers.FlightPath),
  ]);

  useEffect(() => {
    const isCurrentInspectionVisible = !!visibleInspections.find(
      (inspection) => inspection.id === currentInspectionId,
    );
    if (!isCurrentInspectionVisible && visibleInspections.length) {
      const latestInspectionId = visibleInspections.pop()?.id;
      latestInspectionId && dispatch(updateCurrentInspectionId(latestInspectionId));
    }
  }, [visibleInspections]);

  const isShownControlMode = useMemo(() => {
    // NOTE: it's a temporary solution due to a memory leak in the "3D" mode in the "Drawables" section
    const MAX_DRAWABLE_COUNT = 2000;
    const solarPanels = currentReportRef.current?.solar_panels?.features || [];

    const validations = [
      !solarPanels.length,
      solarPanels.length > MAX_DRAWABLE_COUNT,
      !currentReportRef.current?.model_id,
    ];
    return !validations.some(Boolean);
  }, [currentReportRef.current]);

  return (
    <div className={styles.mapWrapper}>
      <SiteCreationPerimeterProgressBar />
      <div ref={mapContainer} className={styles.mapContainer} />
      <Controls
        show={showControls}
        onDynamicZoom={handleDynamicZoom}
        onZoomIn={handleClickZoomIn}
        onZoomOut={handleClickZoomOut}
        isShownControlMode={isShownControlMode}
        isSolarPanelsDisabled={sidebar === ESidebar.Zone && !currentReport}
        isFlightPathDisabled={sidebar === ESidebar.Zone && !currentReport}
        is3DModeDisabled={sidebar === ESidebar.Zone && !currentReport}
      />
    </div>
  );
};
