import _ from 'lodash';
import { ISolarPanel } from '@core/interfaces';

export function getAverageSolarPanelsCoordinates(solarPanels: ISolarPanel) {
  const coordinates = solarPanels.features.reduce(
    (acc, feature) => {
      const solarPanelCoordinates = feature.geometry.coordinates[0];
      const xArray = solarPanelCoordinates.map((coord) => coord[1]);
      const yArray = solarPanelCoordinates.map((coord) => coord[0]);
      const zArray = solarPanelCoordinates.map((coord) => coord[2]);

      return {
        x: [...acc.x, ...xArray],
        y: [...acc.y, ...yArray],
        z: [...acc.z, ...zArray],
      };
    },
    {
      x: [],
      y: [],
      z: [],
    },
  );

  const xMean = _.mean(coordinates.x);
  const yMean = _.mean(coordinates.y);
  const zMean = _.mean(coordinates.z);

  return [xMean, yMean, zMean];
}
